import React from "react";
import { useTranslation } from "react-i18next";

import SelectInput from "./SelectInput";
import TextInput from "./TextInput";





/**
 * Component for showing the input node.
 *
 * @component
 * @example
 * 
 * return (
 *   <InputNode/>
 * )
 */
const InputNode = (props) => {

    const { t } = useTranslation();
    return (
        <>
            { t("nodes."+props.item+".type") == "select" && <SelectInput response={props.response} cohort={props.cohort} item={props.item} value={props.value} submitData={props.submitData} enteredData={props.enteredData} />}
            { t("nodes."+props.item+".type") == "text" && <TextInput response={props.response} cohort={props.cohort} item={props.item} value={props.value} submitData={props.submitData} enteredData={props.enteredData} />}
        </>

    );
};

export default InputNode;
