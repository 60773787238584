import { Box, Typography } from "@material-ui/core";
import React from "react";
import { VictoryBar } from "victory";
import { useTranslation } from "react-i18next";


/**
 * Component for rendering pie charts.
 *
 * @component
 * @example
 * 
 * return (
 *   <Pie/>
 * )
 */
const Bar = (props) => {


    const { t } = useTranslation();
    const handleClick = (event) => {
        props.handleOpen(props.node);
    }

    const handlePopOver = (event) => {
        event.target.style.cursor = "pointer";
        event.target.style.fontWeight = "500";
    }

    const handleReset = (event) => {
        event.target.style.fontWeight = "400";
    }

    return (

        <>
            <Box sx={{ display: "flex" }}>
                <Box>
                    <svg width="20px" height="60px">
                        <g transform={"translate(-45, -41)"}>
                            
                            <VictoryBar
                                width={108}
                                height={157}
                                standalone={false}
                                data={[
                                    { x: 1, y: props.responseVal },
                                    { x: 2, y: props.cohortVal }
                                ]}
                                barWidth={8}
                                style={{
                                    data: {
                                        fill: ({ datum }) => datum.x === 1 ? props.color1 : props.color2
                                    }
                                }}
                                events={[{
                                    target: "data",
                                    eventHandlers: {
                                        onClick: (event) => {
                                            handleClick(event);
                                        },
                                        onMouseOver: (event) => {
                                            event.target.style.cursor = "pointer";
                                        }
                                    }
                                }]}
                            />
                        </g>
                    </svg>
                    
                </Box>
                <Box ml={0.25}>
                    <Typography variant="h2" style={{ color: props.color1, textDecoration: "underline dotted 1px", textDecorationColor: props.decorColor}} onClick={handleClick} onMouseOver={handlePopOver} onMouseOut={handleReset}>{props.label}</Typography>
                    {(props.value === 1 || (props.value).toFixed(1) === "1.0") &&
                        <Typography variant="h2" style={{ color: props.color1, lineHeight: "16.41px", fontWeight: "500"}}>
                            {t("patientprojections.graphlabels.bar.equivalentrisk")}
                        </Typography>
                    }
                    {(props.value !== 1 && (props.value).toFixed(1) !== "1.0") &&
                        <>
                            <Typography variant="body1" style={{ color: props.color1, lineHeight: "18.75px",  fontWeight: "900" }}>
                                {(props.value).toFixed(1)}x
                            </Typography>
                            
                        </>
                    }
                </Box>
            </Box>
            <Box>
                <Typography variant="caption" style={{ color: props.color2, lineHeight: "15px" }}>{props.data === "baselineResponse" ? t("patientprojections.graphlabels.bar.cohortrisk") : t("patientprojections.graphlabels.bar.agegrouprisk")}</Typography> <Typography variant="caption" style={{ color: props.color2, lineHeight: "15px", fontWeight: "500" }}>{props.cohortVal}%</Typography>
            </Box>
        </>
    );
};

export default Bar;
