import React from "react";
import { Box, Dialog, DialogTitle, DialogContent, Link, Button, Checkbox, FormControlLabel } from "@material-ui/core";
import PatientInfo from "../images/patient_info.png";
import { useCookies } from "react-cookie";

/**
 * Component for showing the footer.
 *
 * @component
 * @example
 * 
 * return (
 *   <PatientBackgroundInfoPopup/>
 * )
 */
const PatientBackgroundInfoPopup = (props) => {

    const [cookies, setCookie] = useCookies(["patientBackgroundInfo"]);

    const closeModal = () => {
        sessionStorage.setItem('patientBackgroundChecked', true);
        if(document.querySelector('#neverShow').checked){
            setCookie("patientBackgroundInfo", true);
        }
        props.handleClose();
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose} root className="patient-tips-modal">
            <DialogTitle sx={{ color: "#8250b3" }}>Tips for entering patient background information</DialogTitle>
            <DialogContent>
                <Box className="top-section">
                    <div className='question'>Refer to the tooltips beside each field for help with:</div>
                    <ul>
                        <li>How to enter patient information</li>
                        <li>How patient information is used to calculate projections</li>
                        <li>Default assumptions used when information is not specified</li>
                    </ul>
                </Box>
                <Box className='form-box'>
                    {<img src={PatientInfo} alt="Hovering over the &quot;i&quot; icon activates the tooltip." />}
                </Box>
                <Box className='more-info-area'>
                    For more information about how to use the calculator, see&nbsp;
                    <Link href="/howitworks" color="inherit" underline="always" target="_blank">
                        How the calculator works&nbsp;
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.00781 0.5H9.5V3.99219H8.49219V2.21094L3.59375 7.10938L2.89062 6.40625L7.78906 1.50781H6.00781V0.5ZM8.49219 8.49219V5H9.5V8.49219C9.5 8.75781 9.39844 8.99219 9.19531 9.19531C8.99219 9.39844 8.75781 9.5 8.49219 9.5H1.50781C1.22656 9.5 0.984375 9.39844 0.78125 9.19531C0.59375 8.99219 0.5 8.75781 0.5 8.49219V1.50781C0.5 1.24219 0.59375 1.00781 0.78125 0.804688C0.984375 0.601562 1.22656 0.5 1.50781 0.5H5V1.50781H1.50781V8.49219H8.49219Z" fill="#8250B3"/>
                        </svg>
                    </Link>
                </Box>
                <Box className='modal-footer'>
                    <div className='checkbox-area'>
                        <FormControlLabel style={{ color: "rgba(0, 0, 0, 0.54)" }} control={<Checkbox id="neverShow" name="neverShow" color="primary" />} label="Don't show this again (uses cookies)" />
                    </div>
                    <Button type="button" variant="contained" className="close-button" name="close" onClick={closeModal}>
                        Close
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default PatientBackgroundInfoPopup;
