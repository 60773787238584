import { AppBar, Box, Button, Container, Toolbar, Typography, Tab, Tabs, IconButton, Drawer, MenuItem, Link, Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import header from "../images/header.svg";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MenuIcon from '@mui/icons-material/Menu';
import WarningIcon from "@mui/icons-material/Warning";
import LaunchIcon from '@mui/icons-material/Launch';
import { flexbox } from "@mui/system";


/**
 * Component for showing the header.
 *
 * @component
 * @example
 * 
 * return (
 *   <Header/>
 * )
 */

const Header = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [mobileView, setMobileView] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const headersData = [
        {
            label: "Home",
            href: "/home",
        },
        {
            label: "About COVID-I",
            href: "/about",
        },
        {
            label: "Data Sources",
            href: "/howitworks",
        },
        {
            label: "Terms &amp; Conditions",
            href: "/terms",
        },
    ];

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setMobileView(true)
                : setMobileView(false)
        };

        setResponsiveness();
        console.log(window.innerWidth);
        console.log(mobileView);
        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        };


    }, []);

    const getDrawerChoices = () => {
        return headersData.map(({ label, href }) => {
            return (
                <Link key={label}
                    {...{
                        component: RouterLink,
                        to: href,
                        color: "inherit",
                        style: { textDecoration: "none" },
                        key: label,
                    }}
                >
                    <MenuItem onClick={handleDrawerClose}>{label}</MenuItem>
                </Link>
            );
        });
    };

    const handleDrawerOpen = () =>
        setDrawerOpen(true);

    const handleDrawerClose = () =>
        setDrawerOpen(false);

    

    return (
        <>
            <AppBar position="static" color="secondary">
                <Toolbar disableGutters={true}>
                    <Grid container direction="row" style={{ maxHeight: "64px"}}>
                        <Grid item className="grid-item-logo">
                            <img alt="header" src={header} />
                        </Grid>
                        {!mobileView && <>
                            {(pathname === '/calculator') && <>
                                <Grid item style={{ flexGrow: "1", justifyContent: "center" }}>
                                    <div className="calc-heading">
                                        COVID-19 patient progression calculator
                                    </div>
                                </Grid>
                                <Grid item className="grid-item-back-home">
                                    <Button
                                        style={{ backgroundColor: "#d4c2e5", color: "#8250b3", justifyContent: "center", textAlign: "left", marginTop: "14px", marginRight: "24px"}} size="small"
                                        variant="" onClick={() => navigate('/home')} startIcon={<ArrowBackIosNewIcon fontSize="14px" />}><span className="link-title">Back to COVID-I Home</span>
                                    </Button>
                                </Grid>
                            </>
                            }
                            {(pathname != '/calculator') && <Grid style={{ marginLeft: "auto" }}>
                                <Tabs value={pathname} indicatorColor="primary" textColor="primary">
                                    <Tab label="Home" value="/home" to="/home" component={RouterLink} />
                                    <Tab label="About COVID-I" value="/about" to="/about" component={RouterLink} />
                                    <Tab label="How the calculator works" value="/howitworks" to="/howitworks" component={RouterLink} />
                                    <Tab label="Terms &amp; Conditions" value="/terms" to="/terms" component={RouterLink} />
                                </Tabs>
                            </Grid>
                            }
                        </>
                        }
                    </Grid>
                </Toolbar>
            </AppBar>
            {pathname !== '/home' &&
                <Box bgcolor="white" >
                    <Box bgcolor="white" sx={{ height: "8px" }}>&nbsp;
                    </Box>
                    <Box bgcolor="white" border={1}
                        borderTop={0}
                        borderLeft={0}
                        borderRight={0}
                        borderColor="#D63D3D">
                        <Container maxWidth={false} justifyContent="center">
                            <Box>
                                <Typography variant="h6" style={{ color: "#D63D3D", textAlign: "center", fontWeight:"400" }}>
                                    <WarningIcon fontSize="small" style={{ color: "#D63D3D", position: "relative", top: "1px", height:"12px", width:"13.89px"}} />&nbsp;{t("header.disclaimer.title")}
                                </Typography>
                            </Box>

                            <Box mb={1} display="flex" justifyContent="center" alignItems="center">
                                <Box style={{ width: "848px", lineHeight: "14.06px" }} >
                                    <Box mb={0.5}>
                                        <Typography variant="caption" style={{ color: "#563379", lineHeight: "14.06px", marginBottom: "5px", letterSpacing: "normal" }}>
                                            While we make every effort to make sure the information contained in this website (including any results generated by the COVID Patient Progression Calculator on this website) is accurate and informative, it is for research purposes only and does not constitute or take the place of professional or medical advice
                                        </Typography>

                                    </Box>
                                    <Box mb={0.5}>
                                        <Typography variant="caption" style={{ color: "#563379", lineHeight: "14.06px", marginBottom: "5px", letterSpacing: "normal" }}>
                                            Do not use our information:
                                            <ul>
                                                <li style={{ color: "#563379" }}>to diagnose, treat, cure or prevent any disease;</li>
                                                <li style={{ color: "#563379" }}>for therapeutic purposes; or</li>
                                                <li style={{ color: "#563379" }}>as a substitute for the advice of a health professional.</li>
                                            </ul>
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="caption" style={{ color: "#563379", lineHeight: "14.06px", marginBottom: "5px", letterSpacing: "normal" }}>
                                            We make every effort to provide quality information in our website. However we do not provide any guarantees, and assume no legal liability or responsibility for the accuracy, currency or completeness of the information, or for any injury, loss or damage caused by its use.
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="caption" style={{ color: "#563379", lineHeight: "14.06px", marginBottom: "5px", letterSpacing: "normal" }}>
                                            Your use of the calculator is subject to the <Link href="/terms" underline="always" target="_blank">terms &amp; conditions <LaunchIcon sx={{ fontSize: 13, lineHeight: "12px", position: "relative", top: "2.5px"}}/></Link>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                </Box>
            }
        </>

    );
};

export default Header;
