import { Box, Typography } from "@material-ui/core";
import React from "react";
import { VictoryStack, VictoryBar } from "victory";
import { useTranslation } from "react-i18next";
import Rect1 from "../images/SB_Rectangle1.svg";
import Rect2 from "../images/SB_Rectangle2.svg";
import Rect3 from "../images/SB_Rectangle3.svg";
import Rect4 from "../images/SB_Rectangle4.svg";
/**
 * Component for rendering pie charts.
 *
 * @component
 * @example
 * 
 * return (
 *   <StackedBar/>
 * )
 */
const StackedBar = (props) => {


    const { t } = useTranslation();
    const handleClick = (event) => {
        props.handleOpen(props.node);
    }

    const handlePopOver = (event) => {
        event.target.style.cursor = "pointer";
        event.target.style.fontWeight = "500";
    }

    const handleReset = (event) => {
        event.target.style.fontWeight = "400";
    }

    return (

        <>
            <Box sx={{ display: "flex" }}>
                <Box>
                    <svg width="20px" height="60px">

                        <VictoryStack standalone={false} >
                            <g transform={"translate(-45, -41)"}>
                                <VictoryBar
                                    width={108}
                                    height={150}
                                    standalone={false}
                                    data={[
                                        { x: 1, y: props.responseVal1 },
                                        { x: 2, y: props.cohortVal1 }
                                    ]}
                                    barWidth={8}
                                    style={{
                                        data: {
                                            fill: ({ datum }) => datum.x === 1 ? props.color1 : props.color2
                                        }
                                    }}
                                    events={[{
                                        target: "data",
                                        eventHandlers: {
                                            onClick: () => {
                                                handleClick();
                                            },
                                            onMouseOver: (event) => {
                                                event.target.style.cursor = "pointer";
                                            }
                                        }
                                    }]}
                                />
                            </g>
                            <g transform={"translate(-45, 0)"}>
                                <VictoryBar
                                    width={108}
                                    height={37}
                                    standalone={false}
                                    data={[
                                        { x: 1, y: props.responseVal2 },
                                        { x: 2, y: props.cohortVal2 }
                                    ]}
                                    barWidth={8}
                                    style={{
                                        data: {
                                            fill: ({ datum }) => datum.x === 1 ? props.color3 : props.color4
                                        }
                                    }}
                                />
                            </g>
                        </VictoryStack>

                    </svg>

                </Box>
                <Box ml={0.25}>
                    <Typography variant="h2" style={{ color: props.color1, lineHeight: "15px", textDecoration: "underline dotted" }} onClick={handleClick} onMouseOver={handlePopOver} onMouseOut={handleReset}>{props.label}</Typography>
                    {(props.value1 === 1 || (props.value1).toFixed(1) === "1.0") &&
                        <Typography variant="h2" style={{ color: props.color1, lineHeight: "16.41px", fontWeight: "500" }}>
                            {t("patientprojections.graphlabels.bar.equivalentrisk")}
                        </Typography>
                    }
                    {(props.value1 !== 1 && (props.value1).toFixed(1) !== "1.0") &&
                        <Typography variant="body1" style={{ color: props.color1, lineHeight: "18.75px", fontWeight: "900" }}>
                            {(props.value1).toFixed(1)}x
                        </Typography>

                    }
                </Box>
            </Box>
            <Box>
                <Typography noWrap variant="caption" style={{ color: props.color2, lineHeight: "15px" }}>{props.data === "baselineResponse" ? t("patientprojections.graphlabels.bar.cohortrisk") : t("patientprojections.graphlabels.bar.agegrouprisk")} </Typography> <Typography variant="caption" style={{ color: props.color2, lineHeight: "15px", fontWeight: "500" }}>{props.responseVal1}%</Typography>
            </Box>
            <Box>
                <Typography noWrap variant="caption" style={{ color: props.color2, lineHeight: "15px" }}>{<img src={Rect1} alt="rect1" />}{<img src={Rect2} alt="rect2" />} {props.label1} {props.data === "baselineResponse" ? null : props.value1 + "x"}</Typography>
                <Typography noWrap variant="caption" style={{ color: props.color2, lineHeight: "15px" }}><br />({props.data === "baselineResponse" ? t("patientprojections.graphlabels.stackedbar.cohort") : t("patientprojections.graphlabels.stackedbar.agegroup")} </Typography> <Typography variant="caption" style={{ color: props.color2, lineHeight: "15px", fontWeight: "500" }}>{props.responseVal1}%)</Typography>
            </Box>
            <Box>
                <Typography noWrap variant="caption" style={{ color: props.color2, lineHeight: "15px" }}>{<img src={Rect3} alt="rect3" />}{<img src={Rect4} alt="rect4" />} {props.label2} {props.data === "baselineResponse" ? null : props.value2 + "x"}</Typography>
                <Typography noWrap variant="caption" style={{ color: props.color2, lineHeight: "15px" }}><br />({props.data === "baselineResponse" ? t("patientprojections.graphlabels.stackedbar.cohort") : t("patientprojections.graphlabels.stackedbar.agegroup")}</Typography> <Typography variant="caption" style={{ color: props.color2, lineHeight: "15px", fontWeight: "500" }}>{props.responseVal2}%)</Typography>
            </Box>
        </>
    );
};

export default StackedBar;
