import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import App from "./app/App";
import i18n from "./i18n";
import * as serviceWorker from "./serviceWorker";
import { CookiesProvider } from "react-cookie";



ReactDOM.render((
    <Router>
        <I18nextProvider i18n={ i18n }>
            <CookiesProvider>
                <App />
            </CookiesProvider>
        </I18nextProvider>
    </Router>
  
), document.getElementById("root"));

serviceWorker.unregister();
