import {
    Box,
    Container,
    CircularProgress,
    Backdrop,
    Paper
} from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { useCookies } from "react-cookie";
import PatientBackgroundInfoPopup from "../common/PatientBackgroundInfoPopup";

import PatientBackground from "../patient/PatientBackground";
import PatientProjections from "../patient/PatientProjections";



/**
 * Component for dashboard layout.
 *
 * @component
 * @example
 * 
 * return (
 *   <DashboardLayout/>
 * )
 */
const CalculatorLayout = () => {

    const backendUrl = process.env.REACT_APP_BACKEND_API_URL;
    const [baselineResponse, setBaselineResponse] = useState({});
    // const [adultResponse, setAdultResponse] = useState({});
    // const [seniorResponse, setSeniorResponse] = useState({});
    // const [youngResponse, setYoungResponse] = useState({});
    const youngResponse = useRef();
    const adultResponse = useRef();
    const seniorResponse = useRef();
    const [response, setResponse] = useState({});
    const [resAvailable, setResAvailable] = useState(false);
    const [cohort, setCohort] = useState("NA");
    const [data, setData] = React.useState({});
    const maxYoung = process.env.REACT_APP_MAX_YOUNG;
    const minSenior = process.env.REACT_APP_MIN_SENIOR;
    const [model, setModel] = useState("");

    const [cookies] = useCookies(["patientBackgroundInfo"]);
    const [open, setOpen] = useState(cookies.patientBackgroundInfo || (sessionStorage.getItem('patientBackgroundChecked') === "true") ? false : true);

    const handleClose = () => {
        setOpen(false);
    };
    
    // SM: Not sure why the name and variable are both required, but added both for now
    const responseTypes = {
        "NA": baselineResponse,
        "young": youngResponse.current,
        "adult": adultResponse.current,
        "senior": seniorResponse.current,
    };
    const responseTypeNames = {
        "NA": "baselineResponse",
        "young": "youngResponse",
        "adult": "adultResponse",
        "senior": "seniorResponse",
    };
    const cohortResponse = responseTypes[cohort];
    const cohortResponseName = responseTypeNames[cohort];

    useEffect(() => {
        console.log("backendUrl: ", backendUrl);
        fetch(backendUrl, { method: "GET" })
            .then(res => res.json())
            .then(json => {
                setBaselineResponse(json.data);
                setResponse(json.data);
                setModel(json.model.version);
                setResAvailable(true);
                postData({ ci_age_group_bg: "60" })
                    .then(data => {
                        //setAdultResponse(data.data);
                        adultResponse.current = data.data;
                        postData({ ci_age_group_bg: "93" })
                            .then(data => {
                                //setSeniorResponse(data.data);
                                seniorResponse.current = data.data;
                                postData({ ci_age_group_bg: "22" })
                                    .then(data => {
                                        //setYoungResponse(data.data);
                                        youngResponse.current = data.data;
                                    });
                            });
                    });

            });
    }, [backendUrl]);

    const handleSubmit = (jsonData, debounce = false) => {
        if (debounce) {
            handleSubmit.withDebounce(jsonData);
        }
        else {
            setResAvailable(false);
            postData(jsonData)
                .then(json => {
                    setResponse(json.data);
                    setModel(json.model.version);
                    setResAvailable(true);
                });
        }
    }
    /// SM: I could use a Context for this, but I think this is the only global extra I need for now,
    /// and it's called from submitData anyway, which is already being pushed in
    const debounceTime = 500;
    const debounceTimeout = useRef(null);
    handleSubmit.withDebounce = (...args) => {
        if (debounceTimeout.current !== null) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            handleSubmit(...args);
            debounceTimeout.current = null;
        }, debounceTime);
    };

    async function postData(data = {}) {
        let jsonInput;

        if (Object.keys(data).length === 0) {
            jsonInput = JSON.stringify({});
        } else {
            jsonInput = JSON.stringify(data);
        }
        const response = await fetch(backendUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"

            },
            body: jsonInput
        });
        return response.json();
    }

    const submitData = (key, value, isCohort, debounce = false) => {
        if (key === "ci_age_group_bg") {
            let val = value;
            if (isCohort) {
                if (val.trim() !== "") {
                    if (Number(val) <= Number(maxYoung)) {
                        val = "young";
                    } else if (Number(val) >= Number(minSenior)) {
                        val = "senior";
                    } else {
                        val = "adult";
                    }
                } else {
                    val = "NA";
                }
            } else {
                val = "NA";
            }

            setCohort(val);
        }
        let tempData;
        if (value === "NA" || value.trim() === "") {
            tempData = ({ ...data });
            delete tempData[key];
            if (key === "ci_neut_t0" || key === "ci_lym_t0") {
                delete tempData["nlr"];
            }
            setData(tempData);
        } else {
            tempData = { ...data, [key]: value };
            if (key === "ci_neut_t0" || key === "ci_lym_t0") {
                if (document.getElementById("nlr").value != undefined && document.getElementById("nlr").value.trim() !== "" && document.getElementById("nlr").value !== "NOT SPECIFIED") {
                    tempData = { ...tempData, ['nlr']: document.getElementById("nlr").value };
                }
            }
            setData(tempData);
        }

        handleSubmit(tempData, debounce);
    };

    const refreshData = (key) => {
        let tempData = ({ ...data });
        delete tempData[key];
        setData(tempData);
        handleSubmit(tempData);
    }

    return (
        <>
            <Box
                sx={{
                    backgroundColor: "background.default",
                    minHeight: "100%",
                    my: 1
                }}
            >
                <Container maxWidth={false} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                    <Box sx={{ mt: 2 }}>
                        <PatientBackground response={response} cohort={cohortResponse} setResponse={setResponse} submitData={submitData} refreshData={refreshData} enteredData={data} />
                    </Box>


                    <Box sx={{ mt: 2, position: "relative", mb: 2 }} className="patient-container">
                        <PatientProjections response={response} cohort={cohortResponse} data={cohortResponseName} model={model}/>
                        <Backdrop open={!resAvailable} style={{ position: "absolute", zIndex: "1" }}><CircularProgress color="primary" /></Backdrop>
                    </Box>

                </Container>
            </Box>
            <PatientBackgroundInfoPopup open={open} handleClose={handleClose} />
        </>
    );
};

export default CalculatorLayout;
