import React, { useState, useEffect } from "react";
import { Paper, Box, Dialog, Grid, Typography, Button, Link, CardMedia } from "@material-ui/core";
import Partner1 from "../components/images/digitalhealth.png";
import Partner2 from "../components/images/uos.png";
import Partner3 from "../components/images/monash.png";
import Partner4 from "../components/images/telethonkids.png";
import { useNavigate } from 'react-router-dom';
import WarningIcon from "@mui/icons-material/Warning";
import PlayCircle from "@mui/icons-material/PlayCircle";
import Close from "@mui/icons-material/Close";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import RegisterPopup from "../components/common/RegisterPopup";
import Sample from "../components/images/sample.mp4";




const Home = () => {

    const [cookies] = useCookies(["covidiui"]);
    const [name, setName] = useState("");
    const [organisation, setOrganisation] = useState("");
    const [email, setEmail] = useState("");
    const [remember, setRemember] = useState(false);
    const [role, setRole] = useState("select");

    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(location.state ? location.state.openRegister : false);
    const [demoOpen, setDemoOpen] = useState(location.state ? location.state.openDemo : false);

    const handleClickOpen = () => {
        getCookieData();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    const handleClickDemoOpen = () => {
        setDemoOpen(true);
        let tries = 0;
        const cint = setInterval(() => {
            const vid = document.querySelector('.demoVideoDialog video');
            if (vid || tries++>10) {
                clearInterval(cint);
                vid?.play();
            }
        }, 100);
    };

    const handleDemoClose = () => {
        setDemoOpen(false);
    };

    const getCookieData = () => {
        var cookieCovidI = cookies.covidiui;
        if (cookieCovidI !== undefined) {
            var cookieArr = cookieCovidI.split(";")
            if (cookieArr !== undefined) {
                setName(cookieArr[0]);
                setOrganisation(cookieArr[1]);
                setEmail(cookieArr[2]);
                setRole(cookieArr[3]);
                setRemember(true);
            }
        }
    }

    useEffect(() => {
        document.title = "COVID-I";
        document.querySelector('body').classList.add('homepage');
        getCookieData();
    }, []);

    return (
        <>
            <Paper square="true" elevation="0">
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <Box mt={6} mb={5}>
                            <Typography align="center">
                                <div className="heading-container">
                                    <div className="covid-title">COVID-I is</div>
                                    <div className="covid-title-bg">COVID Intelligence</div>
                                </div>
                                <div className="covid-desc">The COVID-I project is creating tools to help understand and improve the management of COVID-19 disease in clinical settings and our community.</div>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            <Paper square="true" elevation="0">
                <Box mx={30}>
                    <Grid container alignItems="center">
                        <Grid item className="image-container">
                            <Box>
                                <Typography>
                                    <a href="https://www.digitalhealthcrc.com/" rel="noreferrer" target="_blank">{<img src={Partner1} alt="Digital Health CRC" />}</a>
                                </Typography>
                            </Box>
                            <Box>
                                <Typography>
                                    <a href="https://www.sydney.edu.au/" rel="noreferrer" target="_blank">{<img src={Partner2} alt="The University of Sydney" />}</a>
                                </Typography>
                            </Box>
                            <Box>
                                <Typography>
                                    <a href="https://www.monash.edu/" rel="noreferrer" target="_blank">{<img src={Partner3} alt="Monash University" />}</a>
                                </Typography>
                            </Box>
                            <Box>
                                <Typography>
                                    <a href="https://infectiousdiseases.telethonkids.org.au/" rel="noreferrer" target="_blank">{<img src={Partner4} alt="Telethon Kids Institute" />}</a>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
            <Paper square="true" elevation="0">
                <Box className="middle-container">
                    <Grid container>
                        <Grid className="left-section">
                            <Box className="covid-info" mb={5} mr={5}>
                                <Typography color="primary" variant="body1" style={{ lineHeight: "18.75px" }}>
                                    COVID-I is a consortium of research organisations brought together under the CDAP initiative of the Australian Digital Health CRC to research how artificial intelligence can help with the management of the COVID-19 pandemic.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid className="right-section">
                            <Box mt={1.5} className={"link-block"}>
                                <Button
                                    variant="contained" size="small" onClick={() => navigate('/about')} fullWidth startIcon={<ArrowForwardIosIcon fontSize="13px" />}><span className="link-title">About COVID-I</span>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
            <Paper square="true" elevation="0" className="paper-container">
                <Box className="middle-container">
                    <Grid container>
                        <Grid item className="left-section">

                            <Box mt={4}>
                                <Box>
                                    <Typography color="primary" variant="h5">
                                        COVID-19 patient progression calculator
                                    </Typography>
                                </Box>
                                <Box mt={2}>
                                    <Typography variant="subtitle1" style={{ color: "#563379", lineHeight: "19px" }}>
                                        The COVID-I patient progression calculator uses dynamic Bayesian networks trained on real-world data from hospitalised cohorts to predict the likely <b>progression of hospitalised patients with COVID-19.</b>
                                    </Typography>
                                </Box>
                            </Box>
                            <Box mt={2} mb={4}>

                                <Box sx={{ display: "flex" }} mb={3}>

                                    <Box ml={3} mt={3} mb={3} alignItems="center">
                                        <Box>
                                            <Typography color="primary" style={{ lineHeight: "14px" }}>
                                                <Button
                                                    style={{ maxWidth: '148px', maxHeight: '89px', minWidth: '148px', minHeight: '89px', backgroundColor: "#8250B3", color: "white", textAlign: "left", borderRadius: "4px" }}
                                                    variant="contained" onClick={handleClickOpen} startIcon={<ArrowForwardIosIcon fontSize="14px" />}><Typography style={{ lineHeight: "14px", fontSize: "14px", fontWeight: "500" }}>Patient Progression Calculator</Typography>
                                                </Button>
                                            </Typography>
                                        </Box>
                                        <Box mt={2.5}>
                                            <Button className="calculatorVideoButton" onClick={handleClickDemoOpen}>
                                                <img src="images/covid-i_calculator.png" alt="COVID-I calculator demonstration video" style={{ width: 148, height: 88, position: "absolute" }} />
                                                <PlayCircle className="playCircle" />
                                                {/*<img src="images/demo_play.svg" alt="" style={{ width: 148, height: 83 }} /> */}
                                            </Button>
                                        </Box>
                                        <Box sx={{width:153}}>
                                            <Typography variant="caption" color="primary">Watch a calculator tutorial</Typography>
                                        </Box>
                                    </Box>
                                    <Box ml={3} mt={2} mr={1}>
                                        <Box mb={0.25}>
                                            <Typography variant="h5" style={{ color: "#D63D3D" }}>
                                                <WarningIcon fontSize="small" style={{ color: "#D63D3D", position: "relative", top: "2px" }} /> For research purposes only
                                            </Typography>
                                        </Box>
                                        <Box >
                                            <Typography variant="caption" style={{ color: "#563379", lineHeight: "14.06px", marginBottom: "5px", letterSpacing: "normal" }}>
                                                While we make every effort to make sure the information contained in this website (including any results generated by the COVID Patient Progression Calculator on this website) is accurate and informative, it is for research purposes only and does not constitute or take the place of professional or medical advice
                                            </Typography>

                                        </Box>
                                        <Box>
                                            <Typography variant="caption" style={{ color: "#563379", lineHeight: "14.06px", marginBottom: "5px", letterSpacing: "normal" }}>
                                                Do not use our information:
                                                <ul>
                                                    <li style={{ color: "#563379" }}>to diagnose, treat, cure or prevent any disease;</li>
                                                    <li style={{ color: "#563379" }}>for therapeutic purposes; or</li>
                                                    <li style={{ color: "#563379" }}>as a substitute for the advice of a health professional.</li>
                                                </ul>
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="caption" style={{ color: "#563379", lineHeight: "14.06px", marginBottom: "5px", letterSpacing: "normal" }}>
                                                We make every effort to provide quality information in our website. However we do not provide any guarantees, and assume no legal liability or responsibility for the accuracy, currency or completeness of the information, or for any injury, loss or damage caused by its use.
                                            </Typography>
                                        </Box>

                                    </Box>

                                </Box>
                                <Box>
                                    <Typography variant="body1" style={{ color: "#563379" }}>
                                        Users of the calculator may be contacted by the COVID-I project for follow-up research. See <Link href="/terms" underline="always" target="_blank" style={{ color: "#563379" }}>terms &amp; conditions</Link> for more information.
                                    </Typography>
                                </Box>

                            </Box>

                        </Grid>
                        <Grid item className="right-section">
                            <Box mb={2} mt={5}>
                                <Box mt={10} className={"link-block"}>
                                    <Button
                                        variant="contained" size="small" onClick={() => navigate('/howitworks')} fullWidth startIcon={<ArrowForwardIosIcon fontSize="13px" />}><span className="link-title">How the calculator works</span>
                                    </Button>

                                </Box>
                                <Box mt={1} className={"link-block"}>
                                    <Button
                                        variant="contained" size="small" onClick={() => navigate('/howitworks#data-sources')} fullWidth startIcon={<ArrowForwardIosIcon fontSize="14px" />}> <span className="link-title">Data Sources</span>
                                    </Button>

                                </Box>

                                <Box mb={1} mt={3}>
                                    <Typography color="primary" variant="caption">
                                        OTHER RESOURCES
                                    </Typography>
                                </Box>

                                <Box mb={1} mt={1} className="link-block">
                                    <Typography color="primary" style={{ lineHeight: "16.41px", fontSize: "14px", display: "flex" }}>
                                        <ArrowForwardIosIcon fontSize="14px" />&nbsp;<Link href="https://osf.io/bynr6/" underline="always" target="_blank">Underlying models (OSF)</Link>
                                    </Typography>
                                </Box>

                                <Box mb={5} mt={1} className="link-block">
                                    <Typography color="primary" style={{ lineHeight: "16.41px", fontSize: "14px", display: "flex" }}>
                                        <ArrowForwardIosIcon fontSize="14px" />&nbsp;<Link href="https://www.medrxiv.org/content/10.1101/2022.02.14.22270925v1" underline="always" target="_blank">COVID-19 Management Using Bayesian Inference (Preprint)</Link>
                                    </Typography>
                                </Box>
                            </Box>

                        </Grid>
                    </Grid>
                </Box>
            </Paper>
            <Paper square="true" elevation="0">
                <Box className="middle-container">
                    <Grid container>
                        <Grid item className="left-section">
                            <Box mt={4} mb={4}>
                                <Box>
                                    <Typography color="primary" variant="h5" style={{ lineHeight: "28.13px", marginBottom: "16px" }}>
                                        Using the calculator
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography color="primary" variant="body1" style={{ color: "#563379", lineHeight: "19px", marginBottom: "16px", letterSpacing: "normal" }}>
                                        The COVID-I patient progression calculator is a <b>research tool only</b> and is still under development. It is made available to researchers for evaluation purposes only.
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography color="primary" variant="body1" style={{ color: "#563379", lineHeight: "19px", letterSpacing: "normal" }}>
                                        Users of the calculators may be contacted by the COVID-I project for follow-up research. See <Link href="/terms" color="inherit" underline="always">terms &amp; conditions</Link> for more information.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item className="right-section">
                            <Box mt={8.5}>
                                <Box className={"link-block"}>
                                    <Button
                                        variant="contained" size="small" onClick={() => navigate('/terms')} fullWidth startIcon={<ArrowForwardIosIcon fontSize="14px" />}><span className="link-title">Terms &amp; Conditions</span>
                                    </Button>
                                </Box>

                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
            <RegisterPopup open={open} handleClose={handleClose} name={name} organisation={organisation} email={email} remember={remember} role={role} />
            <Dialog open={demoOpen} onClose={handleDemoClose} className="demoVideoDialog">
                <Close fontSize="medium" className="close"  onClick={_=>setDemoOpen(false)}/>
                <CardMedia
                    component="video"
                    image="covidi_demo.mp4"
                    alt="COVID-I Calculator Demonstration Video"
                    controls
                />
            </Dialog>
        </>


    );
}

export default Home;
