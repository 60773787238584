import { useTranslation } from "react-i18next";
import React from "react";
import { Box, Dialog, DialogTitle, DialogContent, Typography, Grid, List, ListItem } from "@material-ui/core";






/**
 * Component for showing the footer.
 *
 * @component
 * @example
 * 
 * return (
 *   <RegisterPopup/>
 * )
 */
const OutputNodesPopup = (props) => {


    const { t } = useTranslation();

    const getData = (val, index) => {
        const label = t("nodes.factordetails." + val + ".label");
        const color = t("nodes.factordetails." + val + ".color")
        return (<ListItem><Typography variant="body1" style={{ color: color }}>{label}</Typography></ListItem>);
    }


    return (

        <Dialog open={props.open} onClose={props.handleClose} fullWidth maxWidth={'sm'}>
            <DialogTitle sx={{ color: t("nodes." + props.node + ".popup.labelcolor") }}>
                {props.node === "ci_pulmonary_support_t1" && <Typography variant="h5">{t("nodes." + props.node + ".popup.pielabel")}</Typography>}
                {props.node !== "ci_pulmonary_support_t1" && <Typography variant="h5">{t("nodes." + props.node + ".popup.label")}</Typography>}
            </DialogTitle>
            <DialogContent>
                <Box>
                    {props.node === "ci_pulmonary_support_t1" && <Typography variant="body1">{t("nodes." + props.node + ".popup.piedetails")}</Typography>}
                    {props.node !== "ci_pulmonary_support_t1" && <Typography variant="body1">{t("nodes." + props.node + ".popup.details")}</Typography>}
                </Box>

                <Box mt={2}>
                    <Typography variant="h6" color="primary">Influencing factors</Typography>
                </Box>
                <Box mt={2}>
                    <Typography variant="body1">{t("nodes." + props.node + ".popup.influencingfactors.factorstext")}</Typography>
                </Box>
                {t("nodes." + props.node + ".popup.influencingfactors.factorslist") !== "NA" &&
                    <Box mt={2}>
                        <List component="ul">
                            {((t("nodes." + props.node + ".popup.influencingfactors.factorslist")).split(",")).map((val, index) => getData(val, index))}
                        </List>
                    </Box>
                }
            </DialogContent>

        </Dialog>

    );
};

export default OutputNodesPopup;
