import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import languageEN from "./app/locate/en/translate.json";

i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        /* debugger For Development environment */
        debug: true,
        defaultNS: "translations",
        /* When react i18next not finding any language to as default in borwser */
        fallbackLng: "en",
        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        },
        keySeparator: ".",
        /* default language when load the website in browser */
        lng: "en",
        ns: ["translations"],
        react: {
            bindI18n: "languageChanged loaded",
            bindStore: "added removed",
            nsMode: "default",
            wait: true
        },
        resources: {
            en: languageEN
        }
    });

export default i18n;
