import { Outlet } from 'react-router-dom';
import React, { useState } from "react";
import Header from '../components/common/Header'
import Footer from '../components/common/Footer'




/**
 * Component for main layout.
 *
 * @component
 * @example
 * 
 * return (
 *   <MainLayout/>
 * )
 */
const MainLayout = () => {

    return (
        <>
            <Header/>
            <Outlet />
            <Footer/>
        </>
    );

};

export default MainLayout;
