import { Box, Link, Typography, Grid } from "@material-ui/core";
import React from "react";

import footer from "../images/footer.svg";



/**
 * Component for showing the footer.
 *
 * @component
 * @example
 * 
 * return (
 *   <Footer/>
 * )
 */
const Footer = () => {

    return (
        <Box className="footer">
            <Grid container justifyContent="space-evenly" alignContent="center">
                <Grid item container xs={3}>
                    <Box ml={2} mt={1}>
                        <Link href="https://www.covidcdap.org/covid-19-cdap/" target="_blank">
                            <img alt="COVID-19 Clinical Data Analytics Platform logo" src={footer} />
                        </Link>
                    </Box>
                </Grid>
                <Grid item xs={6} direction="column" display="flex" container justifyContent="center">
                    <Box  mt={1}>
                        <Typography variant="caption" align="center" component="div" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                            COVID-I is part of the <Link href="https://www.covidcdap.org/" underline="always" color="primary" target="_blank">COVID-19 Clinical Data Analytics Platform (CDAP)</Link> initiative.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item container xs={3}>
                    <Box ml={2}></Box>
                </Grid>
            </Grid>
        </Box>

    );
};

export default Footer;
