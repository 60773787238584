import React, {useEffect} from "react";
import CalculatorLayout from "../components/calculator/CalculatorLayout";
import { Navigate, useLocation } from 'react-router-dom';



const Calculator = () => {

    const location = useLocation();
    const fromRegister = location.state ? location.state.fromRegister : false;

    useEffect(() => {
        document.title = "Calculator";  
        document.querySelector('body').classList.remove('homepage');         
    }, []);

    return (
        <>
            {fromRegister &&  <CalculatorLayout/>}
            {!fromRegister && <Navigate to={"/home"} state={{openRegister : 'true'}}/>}
        </>

    );
}

export default Calculator;

