import { useFormik } from 'formik';
import React from "react";
import { Box, Dialog, DialogTitle, DialogContent, InputLabel, Link, TextField, DialogActions, Button, MenuItem, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { fontSize } from '@mui/system';





/**
 * Component for showing the footer.
 *
 * @component
 * @example
 * 
 * return (
 *   <RegisterPopup/>
 * )
 */
const RegisterPopup = (props) => {


    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [cookies, setCookie, removeCookie] = useCookies(["covidiui"]);
    const navigate = useNavigate();

    const validate = values => {
        const errors = {};
        if (!values.name) {
            errors.name = 'Please provide your name';
        }

        if (!values.organisation) {
            errors.organisation = 'Please provide your organisation/ affiliation';
        }

        if (!values.role || "select" === values.role) {
            errors.role = 'Please select your role';
        }

        if (!values.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Please provide a valid email address';

        }

        if (!values.terms) {
            errors.terms = 'Please provide your organisation/ affiliation';
        }

        return errors;

    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: props.name,
            organisation: props.organisation,
            email: props.email,
            remember: props.remember,
            terms: false,
            role: props.role

        },
        validate,
        onSubmit: (values, { resetForm }) => {
            const json = JSON.stringify(values);
            if (values.remember) {
                //store cookie
                setCookie("covidiui", values.name + ";" + values.organisation + ";" + values.email + ";" + values.role, { path: '/' });
            } else {
                //remove cookie if present
                removeCookie("covidiui");
            }
            const url = backendUrl + "/user";
            const userRequestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
                    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
                },
                body: json,
                mode: 'cors',
            }
            fetch(url, userRequestOptions)
                .then(() => {
                    console.log("User registered")
                    navigate('/calculator', { state: { fromRegister: 'true' } })
                }).catch((error) => {
                    console.log("Could not register")
                });
            //resetForm();

        }
    });

    const resetForm = () => {
        formik.resetForm();
        props.handleClose();
    }





    return (
        <Dialog open={props.open} onClose={props.handleClose} fullWidth maxWidth={'xs'}>
            <DialogTitle disableTypography={true} color="primary" ><Typography variant="h2" style={{ fontSize: "20px", fontWeight: "500", lineHeight: "23.44px" }} >Please Register</Typography></DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Box mt={2}>
                        <InputLabel error={formik.touched.name && formik.errors.name} id={"textfield_name_label"}>Name (required)</InputLabel>
                        <TextField
                            id="name"
                            type="text"
                            fullWidth
                            variant="standard"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            error={formik.touched.name && formik.errors.name}
                            helperText={formik.touched.name && formik.errors.name ? formik.errors.name : null}
                        />
                    </Box>
                    <Box mt={2}>
                        <InputLabel error={formik.touched.organisation && formik.errors.organisation} id={"textfield_organisation_label"}>Organisation/ Affiliation (required)</InputLabel>
                        <TextField
                            id="organisation"
                            type="text"
                            fullWidth
                            variant="standard"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.organisation}
                            error={formik.touched.organisation && formik.errors.organisation}
                            helperText={formik.touched.organisation && formik.errors.organisation ? formik.errors.organisation : null}
                        />
                    </Box>
                    <Box mt={2}>
                        <InputLabel error={formik.touched.role && formik.errors.role} id={"textfield_role_label"}>Role (required)</InputLabel>
                        <TextField select
                            inputProps={{
                                name: "role",
                                id: "role",
                            }}
                            fullWidth
                            variant="standard"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.role}
                            error={formik.touched.role && formik.errors.role}
                            helperText={formik.touched.role && formik.errors.role ? formik.errors.role : null}
                            defaultValue=""
                        >
                            <MenuItem key="select" value="">
                                <em>Select</em>
                            </MenuItem>
                            <MenuItem key="academic" value="academic">Academic</MenuItem>
                            <MenuItem key="clinician" value="clinician">Clinician</MenuItem>
                            <MenuItem key="other" value="other">Other</MenuItem>
                        </TextField>
                    </Box>
                    <Box mt={2}>
                        <InputLabel error={formik.touched.email && formik.errors.email} id={"textfield_email_label"}>Email (required)</InputLabel>
                        <TextField
                            id="email"
                            fullWidth
                            variant="standard"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            error={formik.touched.email && formik.errors.email}
                            helperText={formik.touched.email && formik.errors.email ? formik.errors.email : null}
                        />
                    </Box>
                    <Box mt={2}>
                        <FormControlLabel style={{ color: "rgba(0, 0, 0, 0.54)" }} control={<Checkbox id="remember" color="primary" checked={formik.values.remember} onBlur={formik.handleBlur} onChange={formik.handleChange} />} label="Remember these details for next time. (Accept Cookies)" />
                    </Box>
                    <Box mt={2}>
                        <FormControlLabel style={{ color: "rgba(0, 0, 0, 0.54)" }} control={<Checkbox id="terms" color="primary" error={formik.touched.terms && formik.errors.terms} checked={formik.values.terms} onBlur={formik.handleBlur} onChange={formik.handleChange} />} label={<>I agree to the <Link href="/terms" underline="always" target="_blank">Terms &amp; Conditions</Link> of use (required).</>} />
                    </Box>
                    <Box mt={2}>
                        <Typography style={{ color: "rgba(0, 0, 0, 0.54)" }} variant="caption">By proceeding you agree to occasional contact by COVID-I to provide feedback. You can opt out at any time.</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={resetForm} color="primary">Cancel</Button>
                    {!(formik.isValid && formik.dirty) &&
                        <Button type="submit" disabled={true} style={{ backgroundColor: "#D4C2E5", color: "white" }} size="small" align="center" variant="contained" startIcon={<ArrowForwardIosIcon fontSize="14px" />}>
                            Calculator
                        </Button>
                    }
                    {(formik.isValid && formik.dirty) &&
                        <Button type="submit" style={{ backgroundColor: "#8250B3", color: "white" }} size="small" align="center" variant="contained" startIcon={<ArrowForwardIosIcon fontSize="14px" />}>
                            Calculator
                        </Button>
                    }
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default RegisterPopup;
