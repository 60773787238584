import React from "react";
import { useTranslation, Trans } from "react-i18next";

const TipGraph = (props) => {
    const { t } = useTranslation();
    const toPc = v => Math.round(Number(v)*100)+'%';
    console.log(props);
    
    const lower = v => String(v).match(/[A-Z]/g)?.length > 1 || String(v).search(/[^a-zA-Z ]/)!=-1 ? v : v.toLowerCase();
    
    const totalProb = Object.values(props.response[props.item]).map(v => Number(v)).reduce((a,v)=>a+v);
    const label = t('nodes.'+props.item+'.addlabel', {defaultValue: t('nodes.'+props.item+'.label')});
    const labelLower = lower(t('nodes.'+props.item+'.addlabel', {defaultValue: t('nodes.'+props.item+'.label')}));
    const graphLabel = t('nodes.'+props.item+'.label', {defaultValue: t('nodes.'+props.item+'.addlabel')});
    
    if (totalProb == 0) {
        return <></>;
    }
    else if (props.entered !== undefined) {
        return <div className="entryMessage">You have entered a value of {!isNaN(props.entered) ? props.entered : t('nodes.labels.'+props.entered, {defaultValue:props.entered})}. (To instead estimate a distribution over {labelLower} for this patient, remove this entry.)</div>;
    }
    else {
        const graph = <>
            <div className="distroMessage">The estimated distribution of {labelLower} based on the dataset and your other inputs is shown below.</div>
            <div className="inputGraph">
                <div className="label">{graphLabel}</div>
                {Object.entries(props.response[props.item]).map(([k,v]) => {
                    if (k.search(/^range:/)!=-1) {
                        const [,from,to] = k.match(/^range:(.*)to(.*)$/);
                        const formatNum = v => Number(Number(v).toFixed(3));
                        k = `${formatNum(from)} - ${formatNum(to)}`;
                    }
                    else {
                        k = t('nodes.labels.'+k, {defaultValue: k});
                    }
                    return <>
                        <div className="barRow">
                            <label>{k}</label><span className="barParent"><span className="bar" style={{width: (v*100)+'px'}}></span><span className="pc">{toPc(v)}</span></span>
                        </div>
                    </>;
                })}
                <div className="stats"><Trans i18nKey={'nodes.' + props.item + '.stats'} components={{bold: <strong/>, br: <br/>}}/></div>
            </div>
        </>;
        
        return graph;
    }
}

export default TipGraph;
