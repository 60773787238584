import React, {useEffect} from "react";



const Terms = () => {

    useEffect(() => {
        document.title = "Terms and Conditions"; 
        document.querySelector('body').classList.remove('homepage');          
    }, []);

    return (

        <></>


    );
}

export default Terms;

