import { Box, Typography } from "@material-ui/core";
import React from "react";
import { VictoryBar, VictoryStack, VictoryContainer } from "victory";
import { useTranslation } from "react-i18next";


/**
 * Component for rendering pie charts.
 *
 * @component
 * @example
 * 
 * return (
 *   <Pie/>
 * )
 */
const Bar = (props) => {


    const { t } = useTranslation();
    const handleClick = (event) => {
        props.handleOpen(props.node);
    }

    const handlePopOver = (event) => {
        event.target.style.cursor = "pointer";
        event.target.style.fontWeight = "500";
    }

    const handleReset = (event) => {
        event.target.style.fontWeight = "400";
    }

    const barLogScale = (x, lower = 0.2, upper = 5, bottomMargin = 0) => {
        const barLogScale = ((Math.log(Math.min(upper, Math.max(lower, x))) - Math.log(lower)) / (Math.log(upper) - Math.log(lower))) * (1 - bottomMargin) + bottomMargin;
        console.log("barLogScale is " + barLogScale);
        return barLogScale;
    }
    
    const ratioToCohort = props.responseVal / props.cohortVal;
    const barHeight = barLogScale(ratioToCohort); // Between 0 and 1 (mapped from, e.g., 0.2 to 5)
    const clippedGraphHeight = 61;
    const pointerHeight = 8;
    const pointerWidth = 12;
    const fullGraphHeight = clippedGraphHeight + pointerHeight;
    const scaledBarHeight = (1-barHeight) / fullGraphHeight * clippedGraphHeight;
    const scaledPointerHeight = pointerHeight / fullGraphHeight;
    const pointerOffset = scaledBarHeight * fullGraphHeight;
    

    return (

        <>
            <Box sx={{ display: "flex" }} mb={0}>
                <Box>
                    <svg width="8" height="61" viewBox="0 0 8 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0 H8 V61 H0 V0 Z" fill="#EEE8F3" />
                        <path d="M0 30 H8 V61 H0 V30 Z" fill="#D4C2E5" />
                        <line x1="0" y1="30.5" x2="8" y2="30.5" stroke="#8250B3" />
                    </svg>
                </Box>
                <Box className="customstackedbar">
                    <svg width={pointerWidth} height={fullGraphHeight}>
                        <path d={`M0 ${pointerOffset} H${pointerWidth} V${pointerOffset+pointerHeight} H0 V${pointerOffset} Z`} fill={props.color1} />
                    </svg>
                </Box>

                <Box ml={0.25}>
                    <Typography variant="h2" style={{ color: props.color1, textDecoration: "underline dotted 1px", textDecorationColor: props.decorColor }} onClick={handleClick} onMouseOver={handlePopOver} onMouseOut={handleReset}>{props.label}</Typography>
                    {(props.value === 1 || (props.value).toFixed(1) === "1.0") &&
                        <Typography variant="h2" style={{ color: props.color1, lineHeight: "16.41px", fontWeight: "500" }}>
                            {t("patientprojections.graphlabels.bar.equivalentrisk")}
                        </Typography>
                    }
                    {(props.value !== 1 && (props.value).toFixed(1) !== "1.0") &&
                        <>
                            <Typography variant="body1" style={{ color: props.color1, lineHeight: "18.75px", fontWeight: "900" }}>
                                {(props.value).toFixed(1)}x
                            </Typography>

                        </>
                    }
                </Box>
            </Box>
            <Box ml={1} class="graphCohortLine">
                <Typography variant="caption" style={{ color: props.color2, lineHeight: "14px" }}>{props.data === "baselineResponse" ? t("patientprojections.graphlabels.bar.cohortrisk") : t("patientprojections.graphlabels.bar.agegrouprisk")}</Typography> <Typography variant="caption" style={{ color: props.color2 }} className="percent">({props.cohortVal}%)</Typography>
            </Box>
        </>
    );
};

export default Bar;
