import { Grid, InputLabel, MenuItem, Select, Tooltip, IconButton, TextField  } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";
import TipGraph from "./TipGraph";


/**
 * Component for showing the select box.
 *
 * @component
 * @example
 * 
 * return (
 *   <SelectInput/>
 * )
 */
const SelectInput = (props) => {

    const { t } = useTranslation();

    const [className, setClassName] = useState({backgroundColor: "white", color: "#000000DE", opacity: "0.7"});
    const [inputLabelClassName, setInputLabelClassName] = useState({backgroundColor: "white", fontWeight: "normal", color: "rgba(0, 0, 0, 0.56)", opacity: "1"});

    const getDefaultValue = () => {
        if(props.item === "ci_time_since_admission_bg") return "start0";
        else if(props.item === "ci_period_bg") return "period2";
        else return "NA";
    }
    const updateData = (element) => {
        if(element.value === 'NA') {
            setClassName({...className, backgroundColor: "white", color: "#000000DE", opacity: "0.7"});
            setInputLabelClassName({ ...className, backgroundColor: "white", fontWeight: "normal", color: "rgba(0, 0, 0, 0.56)", opacity: "1" });
        } else {
            setClassName({...className, backgroundColor: "#eee8f3", color: "#8250b3"});
            setInputLabelClassName({ ...className, backgroundColor: "white", fontWeight: "bold", color: "rgba(0, 0, 0, 0.56)", opacity: "1" });
        }
        props.submitData(element.name, element.value);
    };

    return (
        <>
            <Grid item md={3} xs={11} >
                <InputLabel style={inputLabelClassName} id={"select_"+props.item+"_label" }>{ t("nodes."+props.item+".label") }</InputLabel>
                <TextField select
                    fullWidth
                    id={ props.item }
                    name={ props.item }
                    style={ className }                    
                    defaultValue={getDefaultValue}
                    onChange={e => updateData(e.target)}
                    margin="dense"
                    inputProps={{
                        "aria-labelledby":"select_" + props.item + "_label"
                    }}
                >
                    {props.item !== "ci_time_since_admission_bg" && props.item !== "ci_period_bg" &&
                        <MenuItem key="NA" value="NA">{ t("nodes.labels.NA") }</MenuItem>
                    }
                    {Object.keys(props.value).map((item, index) => <MenuItem key={index} value={item}>{ t("nodes.labels."+item) }</MenuItem>)}
                
                </TextField>
            </Grid>
            <Grid item md={1} xs={1}>
                <Tooltip id={"nodes_" + props.item + "_tooltip"} title={<span>{t('nodes.' + props.item + '.info')}{/*<br/><br/><Trans i18nKey={'nodes.' + props.item + '.info2'} components={{bold: <strong/>, br: <br/>}} />*/}<TipGraph response={props.response} item={props.item} entered={props.enteredData[props.item]}/></span>} placement="top-end" role="tooltip">
                    <IconButton style={{borderRadius: 0, width:"24px", height:"24px", position: "relative", padding:"2px", top: "18px"}} aria-label="More information"><InfoIcon aria-describedby={"nodes_" + props.item + "_tooltip"} fontSize="small" style={{ color: "#8150b3"}} /></IconButton>
                </Tooltip>
            </Grid>
        </>
    );
};

export default SelectInput;
