import { Card, CardHeader, CardContent, Box, Typography, Grid, Link } from "@material-ui/core";
import React, { useState } from "react";
import Pie from "../common/Pie";
import { useTranslation } from "react-i18next";
import ShortTerm from "./ShortTerm";
import MediumTerm from "./MediumTerm";
import OutputNodesPopup from "../common/OutputNodesPopup";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from 'react-router-dom';
import LaunchIcon from '@mui/icons-material/Launch';



/**
 * Component for patient projections tile.
 *
 * @component
 * @example
 * 
 * return (
 *   <PatientProjections/>
 * )
 */
const PatientProjections = (props) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [node, setNode] = useState("");
    const navigate = useNavigate();


    const handleClickOpen = (node) => {
        setNode(node);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getPieData = (val) => {

        const response = props.response[val];
        const cohort = props.cohort[val];
        if (response != undefined && cohort != undefined) {
            const responseVal = Math.round(response[t("nodes." + val + ".outputValDisplayPie")] * 100);
            const cohortVal = Math.round(cohort[t("nodes." + val + ".outputValDisplayPie")] * 100);
            const value = Math.round(((responseVal / cohortVal) + Number.EPSILON) * 100) / 100;
            return (

                <Pie label={t("nodes." + val + ".labelPie")} label1={t("nodes." + val + ".labelPie1")} decorColor={t("nodes." + val + ".decorColor")} color={t("nodes." + val + ".colorPie1")} fillcolor={t("nodes." + val + ".colorPie2")} value={responseVal} cohortVal={value} data={props.data} handleOpen={handleClickOpen} node={val}></Pie>

            );
        }
    }

    return (
        <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "50%" }}>
                <Card>
                    <CardHeader
                        disableTypography={true}
                        style={{ backgroundColor: "rgba(247, 243, 249, 1)", height: "133px", paddingTop: "30px" }}
                        title={<Typography color="primary" variant="h3" style={{lineHeight:"40px"}}>{t("patientprojections.title")}</Typography>}
                        subheader={
                            <Box>
                                <Box>
                                    <Typography variant="caption">{t("patientprojections.subtitle")}</Typography>
                                    <Typography variant="caption">&nbsp;{t("patientprojections.subtitlecaption")} <Link href="/howitworks#data-sources" target="_blank" underline="always">{t("patientprojections.subtitlecaptiondatasources")}<LaunchIcon sx={{ fontSize: 12, lineHeight: "12px", position: "relative", top: "2px" }} /></Link></Typography>
                                </Box>
                                <Box>
                                    {props.data === "baselineResponse" &&
                                        <><Typography variant="caption">Comparing patient with:</Typography><Typography variant="caption" color="primary" style={{fontWeight:"500"}}> Entire Cohort</Typography></>
                                    }
                                    {props.data !== "baselineResponse" &&
                                        <><Typography variant="caption">Comparing patient with:</Typography><Typography variant="caption" color="primary" style={{fontWeight:"500"}}> Patient&apos;s age group</Typography></>
                                    }

                                </Box>

                            </Box>
                        }
                    />
                    <CardContent>
                        <Box className="border-section">
                            <ShortTerm response={props.response} cohort={props.cohort} data={props.data} handleOpen={handleClickOpen} />
                        </Box>
                        <Typography variant="caption" style={{ color: "rgba(0, 0, 0, 0.54)" }}>Model version:</Typography> <Typography variant="caption" style={{ color: "rgba(0, 0, 0, 0.54)", fontWeight: "500" }}>{props.model}</Typography>
                    </CardContent>
                </Card>
            </Box>
            <Box sx={{ width: "50%" }}>
                <Card>
                    <CardHeader
                        disableTypography
                        style={{ backgroundColor: "rgba(247, 243, 249, 1)", height: "133px", paddingTop: "23px" }}
                        title={
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box mb={0.5}>
                                        <Typography color="primary" display="inline" variant="h6" style={{ lineHeight: "23.44px", fontSize: "20px" }}>{t("patientprojections.entireepisode.title")}</Typography>
                                        <Typography color="primary" display="inline" variant="h6" style={{ lineHeight: "18.75px" }}>{t("patientprojections.entireepisode.subtitle")}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    {getPieData("ci_status")}
                                </Grid>
                                <Grid item xs={6}>
                                    {getPieData("ci_inv_vent")}
                                </Grid>
                            </Grid>

                        }

                    />
                    <CardContent>
                        <Box>
                            <MediumTerm response={props.response} cohort={props.cohort} data={props.data} handleOpen={handleClickOpen} />
                        </Box>
                        <Typography variant="caption">&nbsp;</Typography>
                    </CardContent>
                </Card>


            </Box>
            <OutputNodesPopup open={open} handleClose={handleClose} node={node} />
        </Box>


    );
};

export default PatientProjections;
