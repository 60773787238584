import React, { useEffect } from "react";

import { Box, Link, Paper, Typography } from "@material-ui/core";

import InfoIcon from "@mui/icons-material/Info";

import CProjection from "../components/images/how_cohortprojection.png";
import CProjectionStatus from "../components/images/how_cohortprojection_status.png";
import EProjections from "../components/images/how_entered_projections.png";
import Background from "../components/images/how_entered_background.png";



const Datasources = () => {

    useEffect(() => {
        document.title = "How the calculator works";
        document.querySelector('body').classList.remove('homepage');
    }, []);


    return (

        <Paper square="true" className="page">
            <style>{`
            .page { display: flex; align-items: center; justify-content: center; }
            .pageContent { max-width: 848px; }
            .pageContent img { max-width: 848px; width: 100%; border: solid 1px #ddd; }
            .MuiTypography-h2 { line-height: 19px; letter-spacing: 0; }
            ul { margin-bottom: 1em; }
            `}</style>
            <Box mx={30} className="pageContent">
                <Box>
                    <Typography>&nbsp;</Typography>
                </Box>
                <Box mt={4}>
                    <Typography color="primary" variant="h4">
                        How the calculator works
                    </Typography>
                </Box>
                <Box mt={1} mb={1}>
                    <Typography variant="h2">
                        <em>(Revised 17 October 2022)</em>
                    </Typography>
                </Box>


                <Box mt={1}>
                    <Typography variant="h2">
                        The COVID-I patient progression calculator uses dynamic Bayesian networks trained on real-world data from hospitalised cohorts to predict the likely progression of COVID-19 patients.
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2">
                        Here we very briefly describe the calculator and how it can be used, as well as provide an overview of the Bayesian network model that drives it.
                    </Typography>
                </Box>


                <Box mt={1}>
                    <Typography color="primary" variant="h5">
                        Who is the calculator for?
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2">
                        It&apos;s very important to note that we have not yet completed validation
                        of the calculator&apos;s outputs, and hence the calculator <strong>should
                            not</strong> be used to inform real-world decisions.
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2">Instead, we are making the tool available now to seek feedback from
                        clinicians and researchers. In particular, we are keen to receive your
                        feedback on:
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2">
                        <ul>
                            <li>How useful the information provided by this kind of tool may be</li>
                            <li>Whether the predictions made by the tool are reasonable</li>
                            <li>How easy it is to interpret and understand the predictions made by
                                the tool</li>
                            <li>What aspects of the tool could be improved</li>
                            <li>Issues the tool might face in real-world settings</li>
                        </ul>
                        <p>In addition, the calculator is intended for researchers and
                            clinicians who would like to evaluate the prospects for a Bayesian
                            network based decision support tool for monitoring and managing COVID-19
                            hospitalised patients, either in the form of a standalone calculator or
                            integrated into a larger, existing decision support or patient
                            management system.</p>
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography color="primary" variant="h5">
                        How to use the calculator
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2">
                        The calculator allows you to enter information about a patient, and
                        in response provides a projection for the patient&apos;s outcomes over the
                        entire patient episode, as well as over the next 24 hours and 5 days.
                        The calculator page is divided into two major sections, with information
                        that you can enter about the patient in the top half, and projections
                        for the patient in the bottom half.
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography color="primary" variant="body1">Interpreting the outputs</Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2">
                        One of the first things you may notice is that projections are
                        available even before you&apos;ve entered any information. The Bayesian
                        network used by the calculator is able to work with partial or no
                        information, so you only need to provide what information you have. When
                        you enter no information, projections are based on the cohort as a
                        whole, essentially giving an estimate of the average risk of death or of
                        mechanical ventilation in the cohort. Here&apos;s an example:
                    </Typography>
                </Box>
                <Box mt={1}><img src={CProjection} width="1000" alt="Example projection for cohort" /></Box>
                <Box mt={1}>
                    <Typography variant="h2">
                        Here, we see that if we enter no information, the estimated risk of
                        death at any time is 10%, while the estimated risk of mechanical
                        ventilation at any time is 5%. Note that these estimates are <em>based
                            on the cohort</em>, which here means patients who were in
                        hospital during June to October 2021 in countries in which data was collected.
                        See <a href="#data-sources">Data Sources</a> for more
                        information on the cohort.
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2">
                        In the above image, you can also see the short term risk (i.e. risk
                        over the next 24 hours) and medium term risk (i.e. risk over the next 5
                        days). For death, these risks are cumulative, hence the risk of death in
                        the short term will always be the same or lower than the medium term
                        risk and both in turn will be the same or lower than the overall risk.
                        In the image above, for the average cohort patient, the risk of death is
                        2% at 24 hours, 8% at 5 days and 10% overall. By contrast, the risk for
                        mechanical ventilation is based on the status at that time (i.e. in 24
                        hours or in 5 days), while the overall risk is the chance of mechanical
                        ventilation at any point. This remains at 5% throughout in the above example.
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2">
                        The second part of the patient projections section estimates the
                        current and medium term status of the patient&apos;s body systems,
                        and in so doing, aims to provide insights
                        into <em>why</em> the model has provided the predicted outcomes that it has. Please note
                        that these estimates of organ system status are not simply post&nbsp;hoc
                        attempts at explanation &mdash; they are fundamental to how the model
                        produces its final outcome predictions. Let&apos;s see how these status
                        outputs look when we&apos;ve entered no information:
                    </Typography>
                </Box>
                <Box mt={1}><img src={CProjectionStatus} width="1000" alt="Example status projections for cohort" /></Box>
                <Box mt={1}>
                    <Typography variant="h2">
                        Each meter provides a visual indication of the relative risk of impaired function
                        as compared to the cohort. When the meter sits above the half way point, the relative
                        risk is elevated (i.e. it is above 1); when the meter sits below, the relative risk
                        is reduced (i.e. it is below 1). The graphs have been divided into 4
                        key body systems: pulmonary, cardiac, vascular and immune.
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2">
                        The graphs in the image above all presently display “Equivalent risk”
                        and all the meters are sitting half way, which simply indicates that the
                        relative risk as compared to the cohort is exactly 1.
                        Since we&apos;ve entered no information, this should be unsurprising, since
                        the current risk is simply the cohort risk. In the next subsection,
                        we&apos;ll see how this changes as we enter information.
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2">
                        Just below each graph is the “Cohort risk”. This is the absolute
                        probability of the impaired function within the cohort.
                        Please note that the meanings of these probabilities are very sensitive
                        to how we define these outputs. For example, “Reduced cardiac function”
                        is defined in a specific way based on heart rate, troponin, diagnoses of
                        heart complications and the presence of treatments that indicate heart
                        problems. The specific definitions for each can be found by clicking the
                        name of the status output. While this is useful for our modelling, the
                        absolute probability of these status outputs for any particular patient
                        is unlikely to be very meaningful. Instead, we have chosen to show just
                        the relative risk, along with the absolute probability for the <em>cohort</em>, so
                        as to aid understanding of how the risk for the
                        current patient compares to the baseline risk of their peers.
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2">
                        Note that, if an age is entered, you can choose instead to compare just to
                        patients in a similar age group. In many cases, this may provide a more useful
                        baseline for comparison, given the critical importance of age.
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography color="primary" variant="h5">Entering information</Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2">
                        To start producing more specific projections for a patient, we can
                        enter some information into the “Patient Background” section. As noted,
                        all of the information in this section is optional. (For those
                        interested, as a rough approximation, the probability distribution from
                        the cohort — conditional on other information you&apos;ve entered — is used
                        for a field when you leave it blank.) When you do enter information into
                        a field such as age or oxygen saturation, the “Patient Projections”
                        section will be updated as you type. 
                    </Typography>

                </Box>
                <Box mt={1}>
                    <Typography variant="h2">
                        Next to each input field you will find an <InfoIcon style={{verticalAlign:"top", fontSize:"18px", color: "#8150b3"}}/> icon. When the mouse
                        hovers over it, an information box is displayed that contains more
                        information about the field, including a definition and summary
                        statistics. The definitions have been written to align with the entry
                        guidelines on the case report forms used by ISARIC/IDDO. (See <a
                            href="#data-sources">Data sources</a>.) If you are in doubt about what
                        information to enter into a field, please consult this definition. Also
                        within the information box, you will find some simple summary statistics
                        on the background or risk factor. For example, in the information box
                        for “Age”, you will find the age distribution in the data set (e.g. 41%
                        young, 40% adult, 19% senior). If you enter a value in this field, the distribution
                        in the information box will be replaced with your entered value. If you enter a value
                        in <em>another</em> field, you will instead see summary statistics based on
                        your other entry. (For example, try setting Chronic cardiac disease to &ldquo;Yes&rdquo;, and then
                        checking Age&apos;s information box.)
                    </Typography>

                </Box>
                <Box mt={1}>
                    <Typography variant="h2">
                        You can specify comorbidities by choosing them from the drop downs in
                        the “Patient comorbidities” subsection. Setting these to “not specified”
                        will instead use the (conditional) averages from the cohort. A variety
                        of patient indicators (normally biomarkers) can also be entered. These
                        are typically numeric, and their required units have
                        been specified next to the indicator title. Again, you can check the distribution
                        of these indicators in the data given any other inputs by consulting the
                        information box (while the entry is empty).
                        Some indicators that we have found to be key are displayed
                        by default; further indicators can be added via “Add/Remove Patient
                        Indicator”.
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2">
                        Below is an example with entries for age, sex, chronic kidney disease
                        (yes), obesity (no), heart rate and D-dimer:
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2"><img src={Background} width="1000" alt="Example background information" /></Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2">and here is how the outputs appear:</Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2"><img src={EProjections} width="1000" alt="Projections after example entered information" /></Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2">For this individual, we can see the overall risk of death has
                        increased to 26%, which is 2.6x higher than the overall cohort. (Were we comparing to
                        this patient&apos;s age group of over 75s, the risk would be 0.9x the risk for that
                        age group.)
                        In the short term, the risk of death is 2%,
                        roughly equivalent to the cohort risk, but 25% in the medium term, and approximately 3.1x
                        the cohort risk.
                        There is a 5% chance of requiring mechanical ventilation at any point
                        during the patient episode, and this is most likely required (4%
                        chance) in the short term.
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2">When we look to see why this particular individual may be at
                        increased or decreased risk, we can take a look at the status of various body
                        functions. In the short term, we can see that the status of pulmonary
                        systems are roughly comparable to the cohort, and the same
                        holds for the immune system. Cardiac function is likely OK, however
                        there is a slight increase in the
                        risk of reduced cardiac
                        output and significant risk of reduced end-organ supply and functional intravascular volume,
                        as well as a substantial risk of abnormal coagulation. This can help
                        us narrow in on where the problems presently appear.</Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2">
                        In the medium term, if the patient survives and has remained in hospital,
                        we can see that the coagulation risk and the risk of reduced end-organ supply
                        will have reduced, the
                        risk of cardiac issues will have increased, while other risks remain
                        similar to their short term counterparts.
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography color="primary" variant="h5" id="data-sources">Data Sources</Typography>
                </Box>

                <Box mt={1}>
                    <Typography variant="h2">
                        The structure of the model was developed in concert with infectious
                        diseases experts from around Australia* between April 2020 and July
                        2021, and also based on datasets from the Lean European Open Survey on
                        SARS-CoV-2 (LEOSS) and the Infectious Diseases Data Observatory
                        (IDDO).
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2">
                        The model used in the present calculator is based on data provided by
                        the IDDO group, and applies to hospitalised patients from 29 countries
                        during the period March 2020 to October 2021. Data collected by IDDO
                        adheres to the ISARIC COVID-19 Case Report Form format. (ISARIC stands
                        for the International Severe Acute Respiratory and Emerging Infection
                        Consortium.)
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2">
                        All cases from 2021 are from Great Britain. For 2020, 86% of cases
                        are from Great Britain, 3.2% from Malaysia, 2.6% from France, 2% from
                        Russia and 1.3% from Brazil, with the remaining countries making up 4.6%
                        of the data. Different data collection periods can be selected
                        by choosing from the &ldquo;Period&rdquo; input box.
                        Further cohort statistics for background factors (such as
                        age) and risk factors can be accessed by hovering over the <InfoIcon style={{verticalAlign:"top", fontSize:"18px", color: "#8150b3"}}/>
                        information icons within the calculator itself.
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2">
                        Note that, the model can be used in two ways. By default, the model
                        assumes the information entered relates to the day of admission. It is
                        also possible to calculate risks for any patient who is <em>presently in
                            hospital</em> on any day after admission. In this mode, the model
                        projects forward dynamically from any point in time during a patient&apos;s
                        stay and will be more likely to include patients who have already been
                        in hospital for some time. This can be achieved in the calculator by changing
                        the &ldquo;Time since admission&rdquo; field.
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography color="primary" variant="h5">A brief note on terminology</Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h2">
                        Just a quick note that, both in this guide and in the tool itself,
                        we&apos;ve made certain terminology choices in the name of simplicity and
                        clarity, while trying to remain as faithful to correct terminology as we
                        can. Hence, we use “risk” rather than “probability” in all situations;
                        we talk of “comorbidities”, even when referring to
                        smoking; and we have grouped certain signs, functions and treatments
                        under individual body systems, even though they may apply to more than
                        one body system. We hope that our particular approach to organising this
                        information enhances rather than detracts from a user&apos;s ability to
                        understand the outputs of the calculator. Of course, if you disagree,
                        please let us know!
                    </Typography>
                </Box>
                <Box>
                    <Typography>&nbsp;</Typography>
                </Box>


            </Box>
        </Paper>


    );
}

export default Datasources;
