import { useTranslation } from "react-i18next";
import React, {useState} from "react";
import { Box, Button, Dialog, DialogTitle, DialogContent, Typography, FormControlLabel, DialogActions, Divider, Checkbox } from "@material-ui/core";
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';




/**
 * Component for showing the footer.
 *
 * @component
 * @example
 * 
 * return (
 *   <RegisterPopup/>
 * )
 */
const OutputNodesPopup = (props) => {


    const { t } = useTranslation();
    const [selectedNodes, setSelectedNodes] = useState([]);

    const handleSubmit = () => {
        props.addNodes(selectedNodes);
    }

    const handleChange = (event) => {
        let tempNodes = selectedNodes;
        const index = tempNodes.indexOf(event.target.name);
        if (index > -1) {
            tempNodes.splice(index, 1);
            props.refreshData(event.target.name);
        }else{
            tempNodes.push(event.target.name)
        }
        setSelectedNodes(tempNodes);
    }

    const getFormControlLabels = (val, index) => {
        if(props.additionalIndicatorNodes.includes(val)){
            return <Box><FormControlLabel key={index} control={<Checkbox id={"checkbox_" + val} icon={<CheckBoxOutlineBlankIcon style={{color:"#8250b3"}}/>} checked="checked" name={val} color="primary" size="small" onClick={handleChange}/>} label={<Typography variant="caption" style={{ color: t("nodes." + val + ".color")}}>{t("nodes." + val + ".addlabel")}</Typography>} /></Box>
        } else {
            return <Box><FormControlLabel key={index} control={<Checkbox id={"checkbox_" + val} icon={<CheckBoxOutlineBlankIcon style={{color:"#8250b3"}}/>} name={val} color="primary" size="small" onClick={handleChange}/>} label={<Typography variant="caption" style={{ color: t("nodes." + val + ".color")}}>{t("nodes." + val + ".addlabel")}</Typography>} /></Box>
        }
    }
    

    return (
        <Dialog open={props.open} onClose={props.handleClose} fullWidth maxWidth={'xs'}>
            <DialogTitle><Typography color="primary" variant="body2">Add patient indicators</Typography></DialogTitle>
            <Divider/>
            <DialogContent>
                {(props.indicatorNodes).map((val, index) => <Box key={"box_" + index}><FormControlLabel key={index} control={<Checkbox id={"checkbox_" + val} checkedIcon={<CheckIcon style={{color:"#8250b3"}} fontSize="small"/>} name={val} color="primary" size="small" checked="true" disabled/>} label={<Typography variant="caption" style={{ color: t("nodes." + val + ".color")}}>{t("nodes." + val + ".addlabel")}</Typography>} /></Box>)}
              
                <Divider/>
                {(props.nodesInd).map((val, index) => getFormControlLabels(val, index))}

            </DialogContent>
            
            <Divider/>
            <DialogActions>
                <Button color="primary" size="small" onClick={props.handleClose}>
                    Cancel
                </Button>
                <Button variant="contained" color="primary" size="small" onClick={handleSubmit}>Ok</Button>
                
            </DialogActions>

        </Dialog>
    );
};

export default OutputNodesPopup;
