import React, { useEffect } from "react";



const CalculatorHelp = () => {

    useEffect(() => {
        document.querySelector('body').classList.remove('homepage');          
    }, []);

    return (

        <></>


    );
}

export default CalculatorHelp;
