import React, { useEffect } from "react";
import { Box, Paper, Typography } from "@material-ui/core";



const About = () => {

    useEffect(() => {
        document.title = "About COVID-I";
        document.querySelector('body').classList.remove('homepage');
    }, []);

   

    return (
        <Paper square="true">
            <Box mx={30}>
                <Box>
                    <Typography>&nbsp;</Typography>
                </Box>
                <Box mt={4}>
                    <Typography color="primary" variant="h4">
                        About COVID-I
                    </Typography>
                </Box>
                <Box mt={1} mb={1}>
                    <Typography variant="h2" style={{ lineHeight: "16.41px" }}>
                        COVID-I is a collaboration between infectious disease and artificial
                        intelligence researchers at Monash University and the University of
                        Sydney and is a part of the COVID-19 Clinical Data Analytics Platform
                        (CDAP) initiative of the Digital Health CRC. We are making use of
                        Bayesian networks to model various aspects of COVID-19, including
                        testing, disease management and prognosis.
                    </Typography>
                </Box>
                <Box mt={1} mb={1}>
                    <Typography variant="h2" style={{ lineHeight: "16.41px" }}>
                        The COVID-I project has drawn on the experience of a range of
                        clinicians and infectious diseases experts around Australia to develop
                        an account of COVID-19 progression, represented in a causal Bayesian
                        network. The calculator here presents an accessible interface to these
                        dynamic causal models, with the goal of eventually aiding clinical
                        decision making around COVID-19 and potentially infectious diseases more
                        broadly.
                    </Typography>
                </Box>

                {/*
                <Box mt={1}>
                    <Typography color="primary" variant="h1">
                        Collaborators
                    </Typography>
                </Box>
                
                <Box mt={1}>
                    <Typography color="primary" variant="body1">
                        Monash University
                    </Typography>
                </Box>
                <Box mt={1} ml={2}>
                    <Typography variant="h2" style={{ lineHeight: "16.41px" }}>
                        <ul><li><span>Pellentesque id tortor ultrices et amet sed. Placerat auctor nunc neque sem varius vestibulum non. Velit vestibulum ac non elit sapien neque, diam bibendum. Nec eget egestas dolor sit. Non malesuada enim non aenean nunc ullamcorper consequat quis dignissim.</span></li></ul>
                    </Typography>
                </Box>
                <Box mt={1} ml={2}>
                    <Typography variant="h2" style={{ lineHeight: "16.41px" }}>
                        <ul><li><span>Nunc imperdiet sit et, scelerisque viverra tincidunt ultricies. Pellentesque ultricies tortor malesuada nec cras est rhoncus lacus, integer. Et, nec in at diam fames et vel senectus a. Facilisis blandit adipiscing proin ipsum. Egestas vitae at ut vel ornare dolor est sed. Diam adipiscing sit condimentum ut neque. Aliquam viverra egestas sed quam ultrices convallis nisl. Volutpat tempor aliquet sollicitudin netus. Sodales id ac vitae morbi.</span></li></ul>
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography color="primary" variant="body1">
                        University of Sydney
                    </Typography>
                </Box>
                <Box mt={1} ml={2}>
                    <Typography variant="h2" style={{ lineHeight: "16.41px" }}>
                        <ul><li><span>Pellentesque id tortor ultrices et amet sed. Placerat auctor nunc neque sem varius vestibulum non. Velit vestibulum ac non elit sapien neque, diam bibendum. Nec eget egestas dolor sit. Non malesuada enim non aenean nunc ullamcorper consequat quis dignissim.</span></li></ul>
                    </Typography>
                </Box>
                <Box mt={1} mb={2} ml={2}>
                    <Typography variant="h2" style={{ lineHeight: "16.41px" }}>
                        <ul><li><span>Nunc imperdiet sit et, scelerisque viverra tincidunt ultricies. Pellentesque ultricies tortor malesuada nec cras est rhoncus lacus, integer. Et, nec in at diam fames et vel senectus a. Facilisis blandit adipiscing proin ipsum. Egestas vitae at ut vel ornare dolor est sed. Diam adipiscing sit condimentum ut neque. Aliquam viverra egestas sed quam ultrices convallis nisl. Volutpat tempor aliquet sollicitudin netus. Sodales id ac vitae morbi.</span></li></ul>
                    </Typography>
                </Box>
                */}
                <Box>
                    <Typography>&nbsp;</Typography>
                </Box>
                

            </Box>
        </Paper>


    );
}

export default About;
