import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Box,
    Link,
    Typography
} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import InputNode from "../common/InputNode";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useTranslation } from "react-i18next";
import AddPatientIndicatorPopup from "../common/AddPatientIndicatorPopup";
import LaunchIcon from '@mui/icons-material/Launch';
import ReplayIcon from '@mui/icons-material/Replay';

const initialNodes = process.env.REACT_APP_DEFAULT_INPUT_NODES;
const initialNodesCom = process.env.REACT_APP_DEFAULT_INPUT_NODES_COM;
const initialNodesInd = process.env.REACT_APP_DEFAULT_INPUT_NODES_IND;
const nodesInd = (process.env.REACT_APP_INPUT_NODES_IND).split(",");

/**
 * Component for patient background tile.
 *
 * @component
 * @example
 * 
 * return (
 *   <PatientBackground/>
 * )
 */
const PatientBackground = (props) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [nodes, setNodes] = useState([]);
    const [indicatorNodes, setIndicatorNodes] = useState([]);
    const [additionalIndicatorNodes, setAdditionalIndicatorNodes] = useState([]);

    const getData = (val, index) => {
        if (props.response[val] != undefined)
            return <InputNode response={props.response} cohort={props.cohort} key={index} item={val} value={props.response[val]} submitData={props.submitData} enteredData={props.enteredData} />;

    }

    const getComData = (val, index) => {
        if (props.response[val] != undefined)
            return <InputNode response={props.response} cohort={props.cohort} key={index} item={val} value={props.response[val]} submitData={props.submitData} enteredData={props.enteredData} />;

    }

    const getIndData = (val, index) => {

        if (props.response[val] != undefined) {
            return (<InputNode response={props.response} cohort={props.cohort} key={"InputNode_" + val + "_" + index} item={val} value={props.response[val]} submitData={props.submitData} enteredData={props.enteredData} />);

        }
    }

    const addNodes = (selectedNodes) => {
        let tempNodes = [];
        setIndicatorNodes(initialNodesInd.split(","));
        (initialNodesInd.split(",")).map((val, index) => tempNodes.push(getIndData(val, index)));
        selectedNodes.map((val, index) => tempNodes.push(getIndData(val, index)));
        setNodes(tempNodes);
        setAdditionalIndicatorNodes(selectedNodes);
        handleClose();
    }

    useEffect(() => {
        let tempNodes = [];
        setIndicatorNodes(initialNodesInd.split(","));
        (initialNodesInd.split(",")).map((val, index) => tempNodes.push(getIndData(val, index)));
        additionalIndicatorNodes.map((val, index) => tempNodes.push(getIndData(val, index)));
        setNodes(tempNodes);
    }, [props.response]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (


        <>
            <Card>
                <CardHeader
                    style={{ backgroundColor: "rgba(247, 243, 249, 1)", height: "133px" }}
                    title={<Typography color="primary" variant="h4">{t("patientbackground.title")}</Typography>}
                    subheader={
                        <>
                            <Box style={{ lineHeight: "13px" }}>
                                <Typography variant="caption" style={{lineHeight:"13px"}}>{t("patientbackground.subtitle1")} <Link href="/howitworks" underline="always" target="_blank">{t("patientbackground.link")}<LaunchIcon sx={{ fontSize: 13, lineHeight: "12px", position: "relative", top: "3px"}}/></Link></Typography>
                            </Box>
                            <Box mt={0.5} style={{ lineHeight: "13px" }}>
                                <Typography variant="caption" style={{lineHeight:"13px"}}>{t("patientbackground.subtitle2")}</Typography>
                            </Box> 
                        </>
                    }
                    action={
                        <Box mr={1}><Grid container><Grid item container direction="row" alignItems="center" justifyContent="flex-end"><Button variant="contained" color="secondary" style={{color: "#8250b3"}} startIcon={<ReplayIcon size="small"/>} onClick={() => window.location.reload(false)}>Reset</Button></Grid></Grid></Box>
                          
                    }
                />
                <CardContent>
                    <Grid
                        container
                        spacing={2}

                    >
                        {(initialNodes.split(",")).map((val, index) => getData(val, index))}


                    </Grid>

                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}><Typography color="primary" variant="h5" style={{lineHeight:"18px"}}>{t("patientstatus.title")}</Typography><Typography variant="caption" style={{color:"rgba(0, 0, 0, 0.54)"}}>{t("patientstatus.subtitle")}</Typography></Grid>
                        {(initialNodesCom.split(",")).map((val, index) => getComData(val, index))}

                    </Grid>

                    <Grid
                        container
                        spacing={2}

                    >
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}><Typography color="primary" variant="h5" style={{lineHeight:"18px"}}>{t("patientindicators.title")}</Typography><Typography variant="caption" style={{color:"rgba(0, 0, 0, 0.54)"}}>{t("patientindicators.subtitle")}</Typography></Grid>

                        {nodes}
                        <Grid item md={3} xs={11} container direction="row" alignItems="flex-end" justifyContent="center">
                            <Button
                                style={{ backgroundColor: "#F0F0F0", color: "#8250b3" }} align="center"
                                variant="contained" onClick={handleClickOpen} startIcon={<AddCircleIcon fontSize="small" />} fullWidth>&nbsp;ADD/REMOVE PATIENT INDICATOR
                            </Button>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>

                </CardContent>
            </Card>
            <AddPatientIndicatorPopup open={open} handleClose={handleClose} nodesInd={nodesInd.filter(item => !indicatorNodes.includes(item))} indicatorNodes={indicatorNodes} addNodes={addNodes} additionalIndicatorNodes={additionalIndicatorNodes} refreshData={props.refreshData} />
        </>


    );
};

export default PatientBackground;

