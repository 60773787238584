import { Navigate } from 'react-router-dom';
import React from "react";
import MainLayout from './components/MainLayout';
import Calculator from './pages/Calculator';
import About from './pages/About';
import CalculatorHelp from './pages/CalculatorHelp';
import Datasources from './pages/Datasources';
import NotFound from './pages/NotFound';
import Terms from './pages/Terms';
import Home from './pages/Home';


const routes = () => [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            { path: 'home', element: <Home /> },
            { path: 'calculator', element: <Calculator /> },
            { path: 'about', element: <About /> },
            { path: 'terms', element: <Terms /> },
            { path: 'help', element: <CalculatorHelp /> },
            { path: 'howitworks', element: <Datasources /> },
            { path: '404', element: <NotFound /> },
            { path: '*', element: <Navigate to="/404" /> },
            { path: '/', element: <Navigate to="/home" /> },
        ]
    }
];

export default routes;
