import { Box, Typography } from "@material-ui/core";
import React from "react";
import { VictoryPie, VictoryLabel, Circle } from "victory";
import { useTranslation, Trans } from "react-i18next";


/**
 * Component for rendering pie charts.
 *
 * @component
 * @example
 * 
 * return (
 *   <Pie/>
 * )
 */
const Pie = (props) => {

    const { t } = useTranslation();
    const handleClick = (event) => {
        props.handleOpen(props.node);
    }

    const handlePopOver = (event) => {
        event.target.style.cursor = "pointer";
    }

    const handleReset = (event) => {
    }


    return (

        <Box sx={{ display: "flex" }}>
            <Box mt={0.5}>
                <svg width="60px" height="60px">
                    <Circle cx="30" cy="30" r="30" fill={props.fillcolor} strokeWidth={3} />
                    <VictoryLabel
                        textAnchor="middle" verticalAnchor="middle"
                        x={30} y={30}
                        text={`${Math.round(props.value)}%`}
                        style={{ fontSize: 24, fill: props.color }}
                    />
                    <g transform={"translate(-45, -45)"}>
                        <VictoryPie
                            standalone={false}
                            animate={{ duration: 1000 }}
                            width={150} height={150}
                            innerRadius={30}
                            data={[{ x: 1, y: props.value }, { x: 2, y: 100 - props.value }]}
                            cornerRadius={0}
                            labels={() => null}
                            style={{
                                data: {
                                    fill: ({ datum }) => {
                                        const color = props.color;
                                        return datum.x === 1 ? color : "transparent";
                                    }
                                }
                            }}
                        />
                    </g>
                </svg>
            </Box>
            <Box ml={1}>
                <Typography variant="caption" style={{ color: props.color, lineHeight: "14.06px" }}>{props.label1}</Typography>
                <Typography variant="h5" style={{ color: props.color, lineHeight: "22px", fontWeight: "500", textDecoration: "underline dotted", textDecorationThickness: "1px", textDecorationColor: props.decorColor }} onClick={handleClick} onMouseOver={handlePopOver} onMouseOut={handleReset}>{props.label}</Typography>
                {props.data === "baselineResponse" &&
                    <>
                        <Typography variant="caption" style={{ color: props.color, lineHeight: "14.06px" }}>
                            {(props.cohortVal === 1 || (props.value).toFixed(1) === "1.0") ? <Trans i18nKey="patientprojections.graphlabels.pie.equivalentcohort" components={{ bold: <strong /> }} /> : <b>{(props.cohortVal).toFixed(1) + "x " + t("patientprojections.graphlabels.pie.cohort")}</b>}
                        </Typography>
                        <Typography variant="caption" style={{ color: props.color, lineHeight: "14.06px" }}></Typography>
                    </>
                }
                {props.data !== "baselineResponse" &&
                    <>
                        {props.cohortVal === 1 &&
                            <Typography variant="caption" style={{ color: props.color, lineHeight: "14.06px" }}>
                                <Trans i18nKey="patientprojections.graphlabels.pie.equivalentagegroup" components={{ bold: <strong /> }} />
                            </Typography>
                        }
                        {props.cohortVal !== 1 &&
                            <>
                                <Typography variant="caption" style={{ color: props.color, lineHeight: "14.06px", fontWeight:"900" }}>{(props.cohortVal).toFixed(1)}x</Typography>
                                <Typography variant="caption" style={{ color: props.color, lineHeight: "14.06px" }}>
                                    <Trans i18nKey="patientprojections.graphlabels.pie.agegrouprisk" />
                                </Typography>
                            </>

                        }

                        <Typography variant="caption" display="block" style={{ color: props.color, lineHeight: "14.06px" }}>{t("patientprojections.graphlabels.pie." + props.data)}</Typography>
                    </>
                }
            </Box>
        </Box>
    );
};

export default Pie;
