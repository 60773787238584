import { createStyles, makeStyles } from "@material-ui/core";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const useStyles = makeStyles(() => createStyles({
    "@global": {
        "#root": {
            height: "100%",
            width: "100%"
        }, 
        "*": {
            boxSizing: "border-box",
            margin: 0,
            padding: 0
        },             
        a: {
            textDecoration: "none"
        },
        body: {
            backgroundColor: "#f4f6f8",
            height: "100%",
            width: "100%"
        },        
        html: {
            "-moz-osx-font-smoothing": "grayscale",
            "-webkit-font-smoothing": "antialiased",            
            height: "100%",
            width: "100%"
        }
    }
}));

const GlobalStyles = () => {
    useStyles();

    return null;
};

export default GlobalStyles;
