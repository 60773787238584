import React, { useEffect } from "react";
import {
    Box,
    Container,
    Typography
} from "@material-ui/core";



const NotFound = () => {

    useEffect(() => {
        document.querySelector('body').classList.remove('homepage');          
    }, []);

    return (

        <Container>
            <Typography
                align="center"
                color="textPrimary"
                variant="h1"
            >
                404: The page you are looking for isn’t here
            </Typography>
            <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
            >
                You either tried some shady route or you came here by mistake.
                Whichever it is, try using the navigation
            </Typography>
            <Box sx={{ textAlign: 'center' }}>
                <img
                    alt="Under development"
                    src="/static/images/undraw_page_not_found_su7k.svg"
                    style={{
                        marginTop: 50,
                        display: 'inline-block',
                        maxWidth: '100%',
                        width: 560
                    }}
                />
            </Box>
        </Container>

    );
}

export default NotFound;
