import { Grid, InputLabel, TextField, Tooltip, IconButton, FormControlLabel, Checkbox, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";
import useDebounce from './use-debounce';
import TipGraph from "./TipGraph";


/**
 * Component for showing the text box.
 *
 * @component
 * @example
 * 
 * return (
 *   <TextInput/>
 * )
 */
const TextInput = (props) => {

    const { t } = useTranslation();
    const [className, setClassName] = useState({});
    const [inputLabelClassName, setInputLabelClassName] = useState({});
    const [helperText, setHelperText] = useState("");
    const [error, setError] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchTermTarget, setSearchTermTarget] = useState(null);
    const [cohortDisabled, setCohortDisabled] = useState(true);
    const [cohortChecked, setCohortChecked] = useState(false);

    console.log('rendertext');

    /*const debouncedSearchTerm = useDebounce(searchTerm, 300);
    
    useEffect(
        () => {
            if (searchTermTarget) {
                updateData(searchTermTarget);
            }
        },
        [debouncedSearchTerm]
    );

    const setSearchTermAndTarget = (element) => {
        
        setSearchTerm(element.value);
        setSearchTermTarget(element);
    }*/

    const checkValue = (element) => {
        // SM: I've moved most of this to updateData
        if (element.value.trim() === "") {
            if (element.name === "ci_age_group_bg"){
                if (!cohortDisabled) {
                    setCohortDisabled(true);
                    // setError(false);
                    // setHelperText("");
                    // setClassName({ ...className, backgroundColor: "white", color: "#000000DE", fontWeight: "normal" });
                    // setInputLabelClassName({ ...className, backgroundColor: "white", fontWeight: "normal", color: "rgba(0, 0, 0, 0.56)" });
                    // updateData(element);
                }
            }
        }
    }

    const updateCohort = (element) => {
        console.log(element.checked);
        setCohortChecked(element.checked);
        props.submitData("ci_age_group_bg", document.getElementById("ci_age_group_bg").value, element.checked);

    }

    const updateData = (element, debounce = false) => {
        console.log("UPDATING");
        let error = false;
        setError(error);
        setHelperText("");
        setInputLabelClassName({ ...className, backgroundColor: "white", color: "rgba(0, 0, 0, 0.54)" });

        if (element.value.trim() === "") {
            if (element.name === "ci_age_group_bg"){
                if (!cohortDisabled) {
                    setError(false);
                    setHelperText("");
                    setClassName({ ...className, backgroundColor: "white", color: "#000000DE", fontWeight: "normal" });
                    setInputLabelClassName({ ...className, backgroundColor: "white", fontWeight: "normal", color: "rgba(0, 0, 0, 0.56)" });
                    //updateData(element);
                }
            }
        }
        
        if (element.value === 'NOT SPECIFIED' || element.value === "") {
            setClassName({ ...className, backgroundColor: "white", color: "#000000DE", fontWeight: "bold" });
            setInputLabelClassName({ ...className, backgroundColor: "white", fontWeight: "normal", color: "rgba(0, 0, 0, 0.56)" });
        } else {
            setClassName({ ...className, backgroundColor: "#eee8f3", color: "#8150b3", fontWeight: "normal" });
            setInputLabelClassName({ ...className, backgroundColor: "white", fontWeight: "bold", color: "rgba(0, 0, 0, 0.56)" });
            if (element.dataset.fieldtype === "number" || element.dataset.fieldtype === "decimal") {
                let re;
                if (element.dataset.fieldtype === "number") {
                    re = /^[0-9\b]+$/;
                } else if (element.dataset.fieldtype === "decimal") {
                    re = /^([0-9]+\.?[0-9]*|\.[0-9]+)$/;
                }
                if (!re.test(element.value)) {
                    setHelperText(element.dataset.fielderror);
                    error = true;
                } else if (Number(element.value) < Number(element.dataset.minvalue)) {
                    setHelperText(element.dataset.minerror);
                    error = true;
                } else if (Number(element.value) > Number(element.dataset.maxvalue)) {
                    setHelperText(element.dataset.maxerror);
                    error = true;
                }
            }
        }

        if (error) {
            setError(true);
            setInputLabelClassName({ ...className, backgroundColor: "white", color: "red", fontWeight: "bold" });
        }

        if (!error || (element.dataset.errortype === "warning")) {

            let val = element.value;

            if (element.name === "ci_lym_t0") {
                const neutVal = document.getElementById("ci_neut_t0").value;
                if (neutVal != undefined && neutVal.trim() !== "" && element.value.trim() !== "") {
                    document.getElementById("nlr").value = (neutVal / element.value).toFixed(1);
                } else {
                    document.getElementById("nlr").value = "";
                }

            } else if (element.name === "ci_neut_t0") {
                const lymVal = document.getElementById("ci_lym_t0").value;
                if (lymVal != undefined && lymVal.trim() !== "" && element.value.trim() !== "") {
                    document.getElementById("nlr").value = (element.value / lymVal).toFixed(1);
                } else {
                    document.getElementById("nlr").value = "";
                }

            }
            
            if(element.name === "ci_age_group_bg"){
                
                if(!cohortDisabled && cohortChecked){
                    props.submitData(element.name, val, true, debounce);
                }else{
                    props.submitData(element.name, val, false, debounce);
                }
            }else {
                props.submitData(element.name, val, false, debounce);
            }

            
        }

    };

    const handleKeyPress = (event) => {
        let element = event.target;
        setHelperText("");
        setError(false);
        if (element.dataset.fieldtype === "number") {
            if (!(event.key >= 0 && event.key <= 9)) {
                event.preventDefault();
                setHelperText(element.dataset.fielderror);
                setError(true);
            }
        }

        if (event.target.dataset.fieldtype === "decimal") {
            if (!(event.key >= 0 && event.key <= 9) && !(event.key == ".")) {
                event.preventDefault();
                setHelperText(event.target.dataset.fielderror);
                setError(true);
            }

        }
        if (element.name === "ci_age_group_bg"){
            setCohortDisabled(false);
        }
    };
    
    
    const neut = 'ci_neut_t0';
    
    const graph = <TipGraph item={props.item} response={props.response} cohort={props.cohort} entered={props.enteredData[props.item]} />;

    return (
        <>
            {props.item === "ci_lym_t0" && <>
                <Grid item md={4} xs={12} className="nlrGrid">
                    <Grid container spacing={2} rowSpacing={1} columnSpacing={4}>
                        <Grid item xs={3}>
                            <InputLabel align="bottom" style={inputLabelClassName} id={"textfield_" + neut + "_label"}>{t("nodes." + neut + ".label")}</InputLabel>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel style={inputLabelClassName} id={"textfield_" + props.item + "_label"}>{t("nodes." + props.item + ".label")}</InputLabel>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel style={inputLabelClassName} id={"textfield_nlrcalculated_label"}>{t("nodes.nlrcalculated.label")}<br/><span style={{visibility:"hidden"}}>(E.g., 1 - 11 10^9/L)</span></InputLabel>
                        </Grid>
                        <Grid item xs={3} />
                        
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id={neut}
                                name={neut}
                                style={className}
                                variant="standard"
                                helperText={helperText}
                                error={error}
                                inputProps={{
                                    'data-fieldtype': t("nodes." + neut + ".vartype"),
                                    'data-fielderror': t("nodes." + neut + ".error"),
                                    'data-minerror': t("nodes." + neut + ".minerror"),
                                    'data-maxerror': t("nodes." + neut + ".maxerror"),
                                    'data-errortype': t("nodes." + neut + ".errortype"),
                                    'data-minvalue': t("nodes." + neut + ".minvalue"),
                                    'data-maxvalue': t("nodes." + neut + ".maxvalue"),
                                    "aria-labelledby":"textfield_" + neut + "_label"
                                }}
                                FormHelperTextProps={{ style: { backgroundColor: 'white' } }}
                                onBlur={e => checkValue(e.target)}
                                //onChange={e => setSearchTermAndTarget(e.target)}
                                onChange={e => updateData(e.target, true)}
                                onKeyPress={e => handleKeyPress(e)}
                                margin="dense"
                            />                   

                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id={props.item}
                                name={props.item}
                                style={className}
                                variant="standard"
                                //helperText={helperText}
                                //error={error}
                                inputProps={{
                                    'data-fieldtype': t("nodes." + props.item + ".vartype"),
                                    'data-fielderror': t("nodes." + props.item + ".error"),
                                    'data-minerror': t("nodes." + props.item + ".minerror"),
                                    'data-maxerror': t("nodes." + props.item + ".maxerror"),
                                    'data-errortype': t("nodes." + props.item + ".errortype"),
                                    'data-minvalue': t("nodes." + props.item + ".minvalue"),
                                    'data-maxvalue': t("nodes." + props.item + ".maxvalue"),
                                    "aria-labelledby":"textfield_" + props.item + "_label"
                                }}
                                FormHelperTextProps={{ style: { backgroundColor: 'white' } }}
                                onBlur={e => checkValue(e.target)}
                                //onChange={e => setSearchTermAndTarget(e.target)}
                                onChange={e => updateData(e.target, true)}
                                onKeyPress={e => handleKeyPress(e)}
                                margin="dense"
                            />

                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                disabled
                                id="nlr"
                                name="nlr"
                                style={className}
                                inputProps= {{"aria-labelledby": "textfield_nlrcalculated_label"}}                        
                                placeholder="NOT SPECIFIED"
                                variant="standard"
                                onBlur={e => checkValue(e.target)}
                                //onChange={e => setSearchTermAndTarget(e.target)}
                                onChange={e => updateData(e.target, true)}
                                margin="dense"
                                InputProps={{ disableUnderline: true }}
                                
                            />

                        </Grid>
                        <Grid item xs={3}>
                            <Tooltip id={"nodes_" + props.item + "_tooltip"} title={<span>
                                {t('nodes.' + props.item + '.info')}
                                <TipGraph item="ci_neut_t0" response={props.response} cohort={props.cohort} entered={props.enteredData["ci_neut_t0"]} />
                                <TipGraph item="ci_lym_t0" response={props.response} cohort={props.cohort} entered={props.enteredData["ci_lym_t0"]} />
                            </span>} placement="top-end" role="tooltip">
                                <IconButton style={{borderRadius: 0, width:"24px", height:"24px", position: "relative", padding:"2px", top: "6px"}} aria-label="More information"><InfoIcon aria-describedby={"nodes_" + props.item + "_tooltip"} fontSize="small" style={{ color: "#8150b3" }} /></IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </>}
            {props.item != "ci_neut_t0" && props.item != "ci_lym_t0" && <>
                <Grid item md={3} xs={11} direction="column" align="left" style={{ display: "flex", justifyContent: "flex-start" }}>
                    <InputLabel style={inputLabelClassName} id={"textfield_" + props.item + "_label"}>{t("nodes." + props.item + ".label")}</InputLabel>
                    <TextField
                        fullWidth
                        id={props.item}
                        name={props.item}
                        style={className}
                        placeholder="NOT SPECIFIED"
                        variant="standard"
                        helperText={helperText}
                        error={error}                        
                        inputProps={{
                            'data-fieldtype': t("nodes." + props.item + ".vartype"),
                            'data-fielderror': t("nodes." + props.item + ".error"),
                            'data-minerror': t("nodes." + props.item + ".minerror"),
                            'data-maxerror': t("nodes." + props.item + ".maxerror"),
                            'data-errortype': t("nodes." + props.item + ".errortype"),
                            'data-minvalue': t("nodes." + props.item + ".minvalue"),
                            'data-maxvalue': t("nodes." + props.item + ".maxvalue"),
                            "aria-labelledby":"textfield_" + props.item + "_label"
                        }}
                        sx={{
                            '& .MuiInput-underline:before': { borderBottomColor: 'orange' },
                            '& .MuiInput-underline:after': { borderBottomColor: 'orange' },
                        }}
                        FormHelperTextProps={{ style: { backgroundColor: 'white' } }}
                        onBlur={e => checkValue(e.target)}
                        //onChange={e => setSearchTermAndTarget(e.target)}
                        onChange={e => updateData(e.target, true)}
                        onKeyPress={e => handleKeyPress(e)}
                        margin="dense"
                        size="medium"
                    />
                    {props.item === "ci_age_group_bg" &&
                        <FormControlLabel disableTypography={true} control={<Checkbox onChange={e => updateCohort(e.target)} id="cohort" disabled={cohortDisabled} color="primary" size="small" />} label={<Typography variant="h2">Compare patient with age group</Typography>} />
                
                    }

                </Grid>
                <Grid item md={1} xs={1} >
                    <Tooltip TransitionComponent={React.Fragment} style={{whiteSpace: 'pre-line' }} id={"nodes_" + props.item + "_tooltip"} title={<div>{t('nodes.' + props.item + '.info')}{/*<br/><br/><Trans i18nKey={'nodes.' + props.item + '.info2'} components={{bold: <strong/>, br: <br/>}}/>*/}<span>{graph}</span></div>} placement="top-end" role="tooltip">
                        <IconButton style={{borderRadius: 0, width:"24px", height:"24px", position: "relative", padding:"2px", top: "18px"}} aria-label="More information"><InfoIcon aria-describedby={"nodes_" + props.item + "_tooltip"} fontSize="small" style={{ color: "#8150b3" }} /></IconButton>
                    </Tooltip>
                </Grid>
            </>}
        </>

    );
};

export default TextInput;
