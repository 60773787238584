import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { useRoutes } from 'react-router-dom';
import React from "react";
import routes from './routes';
import GlobalStyles from "./components/GlobalStyles";
import "./App.css";



const App = () => {

    // Or Create your Own theme:
    const theme = createTheme({
        overrides: {
            MuiSelect: {
                select: {
                    borderColor: "#bdbdbd",
                    "&:focus": {
                        borderColor: "#bdbdbd",
                    },
                    '&:before': {
                        borderColor: "#bdbdbd",
                    },
                    '&:after': {
                        borderColor: "#bdbdbd",
                    },
                    '&:hover': {
                        borderColor: "#bdbdbd",
                    }
                }

            },
            MuiIconButton:{
                root:{
                    paddingLeft:0,
                    paddingTop:"20px"
                }
            },
            MuiFormControl: {
                marginDense: {
                    marginTop: "3px"
                }
            },

            MuiFormLabel: {
                root: {
                    fontSize: ".75rem"
                }
            },

            MuiInputBase: {
                input: {
                    paddingLeft: "2px"
                },
                inputMarginDense: {
                    paddingTop: "6px"
                }
            },

            MuiInput: {
                input: {
                    "&::placeholder": {
                        color: "#000000DE",
                        opacity:.7
                    },

                }
            },

            MuiTooltip: {
                tooltip: {
                    fontSize: ".75rem",
                    color: "#8250b3",
                    backgroundColor: "#eee8f3"
                }
            },

            MuiCardHeader: {
                
                action: {
                    flexGrow: 1
                }
            },

            MuiContainer: {
                root: {
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    maxWidth:"1600px",
                    margin: "auto",
                }

            },

            MuiPaper: {
                rounded: {
                    borderRadius: "0px"
                }
            },

            MuiTypography: {
                h3: {
                    lineHeight: "56px",
                    fontWeight: "500",
                    fontSize: "48px"
                },
                h4: {
                    lineHeight: "34px",
                    fontWeight: "500",
                    fontSize: "34px"
                },
                h5: {
                    lineHeight: "28px",
                    fontWeight: "400",
                    fontSize: "24px"
                },
                h6: {
                    lineHeight: "16px",
                    fontWeight: "500",
                    fontSize: "16px"
                },
                caption: {
                    lineHeight: "12px",
                    fontWeight: "400",
                    fontSize: "12px"
                },
                body1: {
                    fontSize: "16px",
                    lineHeight: "16.41px"
                },
                body2: {
                    fontSize: "20px",
                    fontWeight: "500",
                    lineHeight: "23px"
                },
                h2: {
                    lineHeight: "15px",
                    fontWeight: "400",
                    fontSize: "14px"
                },
                h1: {
                    lineHeight: "28.13px",
                    fontWeight: "400",
                    fontSize: "24px"
                },
                h7: {
                    lineHeight: "16.41px",
                    fontWeight: "400",
                    fontSize: "14px"
                },
                
            },
            
            MuiTab: {
                textColorPrimary: {
                    color: "rgba(0, 0, 0, 0.87)"
                },
                root: {
                    minWidth: "100px",
                    minHeight: "64px"
                }

            },

            MuiTabs: {
                indicator: {
                    paddingBottom: 0
                }
            },

            MuiButton: {
                root:{
                    borderRadius:"0px"
                },
                contained: {
                    boxShadow: "none"
                },
                startIcon: {
                    marginRight: 0
                },
                iconSizeSmall: {
                    '& > *:first-child': {
                        fontSize: 13,
                    },
                }
            },
            MuiDialog: {
                paperWidthXs: {
                    maxWidth: "300px"
                },
                paperWidthSm: {
                    maxWidth: "560px"
                },
                paper: {
                    minHeight: "505px",
                    minWidth: "300px",
                    maxWidth: "300px"
                }
            },

            MuiDialogTitle: {
                root: {
                    marginTop:"5px",
                    color: '#8250b3'
                }
            },
            MuiDialogContent: {
                root: {
                    marginTop:"0px",
                    paddingTop:"0px",
                    '&:first-child': {
                        paddingTop:"0px",
                    },
                   
                }
            }
        },


        palette: {
            primary: {
                main: "#8250b3"
            },
            secondary: {
                main: "#eee8f3"
            }
        }


    });

    const routing = useRoutes(routes());

    return (

        <MuiThemeProvider theme={theme}>
            <GlobalStyles />
            {routing}
        </MuiThemeProvider>

    );
};

export default App;
