import { Grid, Typography, Box } from "@material-ui/core";
import React from "react";
import Pie from "../common/Pie";
import Bar from "../common/Bar";
import StackedBar from "../common/StackedBar";
import CustomStackedBar from "../common/CustomStackedBar";

import { useTranslation } from "react-i18next";





/**
 * Component for short term tile inside the patient projections tile.
 *
 * @component
 * @example
 * 
 * return (
 *   <MediumTerm/>
 * )
 */
const MediumTerm = (props) => {

    const outputNodesRiskMedium = process.env.REACT_APP_DEFAULT_OUTPUT_NODES_RISK_MEDIUM;
    const outputNodesTreatMedium = process.env.REACT_APP_DEFAULT_OUTPUT_NODES_TREAT_MEDIUM;

    const outputNodesRiskMediumPulmonary = process.env.REACT_APP_DEFAULT_OUTPUT_NODES_RISK_MEDIUM_PULMONARY;
    const outputNodesRiskMediumCardiac = process.env.REACT_APP_DEFAULT_OUTPUT_NODES_RISK_MEDIUM_CARDIAC;
    const outputNodesRiskMediumVascular = process.env.REACT_APP_DEFAULT_OUTPUT_NODES_RISK_MEDIUM_VASCULAR;
    const outputNodesRiskMediumImmune = process.env.REACT_APP_DEFAULT_OUTPUT_NODES_RISK_MEDIUM_IMMUNE;

    const outputNodesTreatMediumPulmonary = process.env.REACT_APP_DEFAULT_OUTPUT_NODES_TREAT_MEDIUM_PULMONARY;
    const outputNodesTreatMediumCardiac = process.env.REACT_APP_DEFAULT_OUTPUT_NODES_TREAT_MEDIUM_CARDIAC;
    const outputNodesTreatMediumVascular = process.env.REACT_APP_DEFAULT_OUTPUT_NODES_TREAT_MEDIUM_VASCULAR;
    const outputNodesTreatMediumImmune = process.env.REACT_APP_DEFAULT_OUTPUT_NODES_TREAT_MEDIUM_IMMUNE;


    const { t } = useTranslation();

    const getData = (val, index) => {
        if (val === "NA") {
            return (
                <Box item xs={3}>

                </Box>
            )
        }
        const response = props.response[val];
        const cohort = props.cohort[val];
        if (response != undefined && cohort != undefined) {
            if (t("nodes." + val + ".outputValDisplay").includes(",")) {
                const valArr = t("nodes." + val + ".outputValDisplay").split(",");
                const responseVal1 = Math.round(response[valArr[0]] * 100);
                const cohortVal1 = Math.round(cohort[valArr[0]] * 100);
                const responseVal2 = Math.round(response[valArr[1]] * 100);
                const cohortVal2 = Math.round(cohort[valArr[1]] * 100);
                const value1 = Math.round(((responseVal1 / cohortVal1) + Number.EPSILON) * 100) / 100;
                const value2 = Math.round(((responseVal2 / cohortVal2) + Number.EPSILON) * 100) / 100;
                return (
                    <Box mb={2} item xs={12}>
                        <StackedBar label={t("nodes." + val + ".label")}
                            label1={t("nodes." + val + ".label1")}
                            label2={t("nodes." + val + ".label2")}
                            color1={t("nodes." + val + ".color1")}
                            color2={t("nodes." + val + ".color2")}
                            color3={t("nodes." + val + ".color3")}
                            color4={t("nodes." + val + ".color4")}
                            decorColor={t("nodes." + val + ".decorColor")}
                            responseVal1={responseVal1}
                            cohortVal1={cohortVal1}
                            value1={value1}
                            responseVal2={responseVal2}
                            cohortVal2={cohortVal2}
                            value2={value2}
                            data={props.data}
                            handleOpen={props.handleOpen}
                            node={val} />
                    </Box>
                );
            } else {
                const responseVal = Math.round(response[t("nodes." + val + ".outputValDisplay")] * 100);
                const cohortVal = Math.round(cohort[t("nodes." + val + ".outputValDisplay")] * 100);
                const value = Math.round(((responseVal / cohortVal) + Number.EPSILON) * 100) / 100;
                return (
                    <Box mb={2} item xs={12}>
                        <CustomStackedBar label={t("nodes." + val + ".label")} color1={t("nodes." + val + ".color1")} color2={t("nodes." + val + ".color2")} decorColor={t("nodes." + val + ".decorColor")} responseVal={responseVal} cohortVal={cohortVal} value={value} data={props.data} handleOpen={props.handleOpen} node={val}/>
                    </Box>
                );
            }
        }
    }

    const getPieData = (val) => {

        const response = props.response[val];
        const cohort = props.cohort[val];
        if (response != undefined && cohort != undefined) {
            const responseVal = Math.round(response[t("nodes." + val + ".outputValDisplayPie")] * 100);
            const cohortVal = Math.round(cohort[t("nodes." + val + ".outputValDisplayPie")] * 100);
            const value = Math.round(((responseVal / cohortVal) + Number.EPSILON) * 100) / 100;
            return (

                <Pie label={t("nodes." + val + ".labelPie")} label1={t("nodes." + val + ".labelPie1")} color={t("nodes." + val + ".colorPie1")} fillcolor={t("nodes." + val + ".colorPie2")} decorColor={t("nodes." + val + ".decorColor")} value={responseVal} cohortVal={value} data={props.data} handleOpen={props.handleOpen} node={val}></Pie>

            );
        }
    }

    return (

        <Grid container alignContent="space-around" justifyContent="space-evenly">
            <Grid item xs={12}>
                <Box mb={0.5}>
                    <Typography color="primary" display="inline" variant="h6" style={{ lineHeight: "23.44px", fontSize: "20px" }}>{t("patientprojections.mediumterm.title")}</Typography>
                    <Typography color="primary" display="inline" variant="h6" style={{ lineHeight: "18.75px" }}>{t("patientprojections.mediumterm.subtitle")}</Typography>
                </Box>
            </Grid>
            <Grid item xs={6} >
                {getPieData("ci_status_t1")}
            </Grid>
            <Grid item xs={6}>
                {getPieData("ci_inv_vent_t0_to_t1")}
            </Grid>
            <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} style={{paddingBottom:"2px"}}><Typography variant="body1" color="primary" style={{ fontWeight: 500 }}>Organ status in 5 days</Typography></Grid>
                <Grid item xs={3} rowSpacing={5}>
                    <Typography variant="body1" style={{ color: "rgba(0, 0, 0, 0.56)", fontWeight: 500 }}>Pulmonary</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body1" style={{ color: "rgba(0, 0, 0, 0.56)", fontWeight: 500 }}>Cardiac</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body1" style={{ color: "rgba(0, 0, 0, 0.56)", fontWeight: 500 }}>Vascular</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body1" style={{ color: "rgba(0, 0, 0, 0.56)", fontWeight: 500 }}>Immune System</Typography>
                </Grid>

                <Grid item xs={3}>
                    {(outputNodesRiskMediumPulmonary.split(",")).map((val, index) => getData(val, index))}
                </Grid>
                <Grid item xs={3}>
                    {(outputNodesRiskMediumCardiac.split(",")).map((val, index) => getData(val, index))}
                </Grid>
                <Grid item xs={3}>
                    {(outputNodesRiskMediumVascular.split(",")).map((val, index) => getData(val, index))}
                </Grid>
                <Grid item xs={3}>
                    {(outputNodesRiskMediumImmune.split(",")).map((val, index) => getData(val, index))}
                </Grid>
                {/*
                <Grid item xs={12}>
                    <Typography variant="body1" style={{ color: "rgba(0, 0, 0, 0.56)" }}>Treatments</Typography>
                </Grid>

                <Grid item xs={3}>
                    {(outputNodesTreatMediumPulmonary.split(",")).map((val, index) => getData(val, index))}
                </Grid>
                <Grid item xs={3}>
                    {(outputNodesTreatMediumCardiac.split(",")).map((val, index) => getData(val, index))}
                </Grid>
                <Grid item xs={3}>
                    {(outputNodesTreatMediumVascular.split(",")).map((val, index) => getData(val, index))}
                </Grid>
                <Grid item xs={3}>
                    {(outputNodesTreatMediumImmune.split(",")).map((val, index) => getData(val, index))}
                </Grid>
                */}
            </Grid>
        </Grid>

    );
};

export default MediumTerm;
